<template>
  <div id="restaurant-detail">
    <div class="right-content">
      <div class="restaurant-detail-header" v-if="!this.$store.state.isLoading">
        <img :src="restaurant_detail.coverImage" alt="" class="banner-img" />
        <div class="restaurant-logo">
          <img :src="restaurant_detail.profile_image" alt="" />
        </div>
      </div>
      <div class="restaurant-wrapper" v-if="!this.$store.state.isLoading">
        <div class="flex-column">
          <div class="restaurant-name mb-2">{{ restaurant_detail.name }}</div>

          <div>
            <b> {{ restaurant_detail.restaurantId }}</b>
          </div>
<!--          <b v-if="todayTimeSlot"-->
<!--            >Lunch Time: {{ todayTimeSlot ? todayTimeSlot.lunch_start_time : "" }} - -->
<!--            {{ todayTimeSlot ? todayTimeSlot.lunch_end_time : "" }}<br>-->
<!--            Dinner Time: {{ todayTimeSlot ? todayTimeSlot.dinner_start_time : "" }} - -->
<!--            {{ todayTimeSlot ? todayTimeSlot.dinner_end_time : "" }}</b-->
<!--          >-->

<!--          <a v-if="todayTimeSlot" style="margin-left: -15px;">Open</a>-->

          <b v-if="todayTimeSlot">Open</b>
          <b v-if="!todayTimeSlot">Closed</b>
          <star-rating
            :read-only="true"
            v-bind:increment="0.5"
            v-bind:max-rating="5"
            :rating="restaurant_detail.rating"
            inactive-color="#666"
            active-color="#5CB700"
            v-bind:star-size="12"
          >
          </star-rating>
        </div>
        <div class="info-btn-wrapper">
          <b-button v-b-modal.about-restaurant class="info-btn"
            ><i class="fas fa-info"></i
          ></b-button>
          <AboutRestaurant
            v-if="showComponent"
            :restaurantDetail="aboutRestaurant"
            :todayTimeSlot="todayTimeSlot"
            :reviewsList="reviewsList"
            @forPagination="forPagination"
          />
          <button class="info-btn" v-if="this.$store.state.isAuthenticated">
            <i
              v-if="!this.isFav"
              @click="favouriteRestaurant(restaurant_detail, 1)"
              class="far fa-heart"
            ></i>
            <i
              v-if="this.isFav"
              class="fas fa-heart"
              style="color: #5cb700"
              @click="favouriteRestaurant(restaurant_detail, 0)"
            ></i>
          </button>
        </div>
      </div>
      <div class="info-btn-wrapper temp" v-if="!this.$store.state.isLoading" style="column-gap: 1px; font-size: 15px!important;">
        <button class="show-btn button-align " v-b-modal.reserve-booking >{{ $t("bookReservation") }}</button>
        <button class="show-btn button-align" v-b-modal.staff-temperature >{{ $t("staffTemperature") }}</button>

<!--        <b-button v-b-modal.reserve-booking class="info-btn"-->
<!--          ><img-->
<!--            :src="'../images/reservation.png'"-->
<!--            style="margin-left: -9px; height: 39px; margin-top: -2px"-->
<!--        /></b-button>-->
        <ReserveBooking />
<!--        <b-button v-b-modal.staff-temperature class="info-btn"-->
<!--          ><img-->
<!--            :src="'../images/staff_temperature.png'"-->
<!--            style="margin-left: -9px; height: 39px; margin-top: -3px"-->
<!--          />-->
<!--        </b-button>-->
        <StaffTemperature
          v-if="!this.$store.state.isLoading"
          :staffTemperaturelList="staffTemperaturelList"
        />
      </div>
      <div class="restaurant-desc" v-if="!this.$store.state.isLoading">
        {{ restaurant_detail.long_description }}
      </div>
      <ImageLoader v-if="this.$store.state.isLoading" />

      <div v-if="!this.$store.state.isLoading">
        <hooper :settings="hooperSettings">
          <slide @click="selected = index">
            <div
              class="scrollactive-item"
              @click="filterCategory()"
              :class="{ active: selected == 0 }"
            >
              All
            </div>
          </slide>
          <slide @click="selected = index">
            <div
              class="scrollactive-item"
              @click="filterCategory('deals')"
              :class="{ active: selected == 'deals' }"
            >
              Deals
            </div>
          </slide>
          <slide v-for="(data1, index) in this.categoriesList" :key="index">
            <div
              @click="filterCategory(stringConverg(index))"
              class="scrollactive-item"
              :class="{ active: selected == stringConverg(index) }"
            >
              {{ index }}
            </div>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
        <div class="navbar-bottom" v-if="!discountcomponent">
          <div id="popular-dishes">
            <RecordNotFound v-if="this.categoriesList.length < 1" />
            <div class="dish mb-2" v-if="this.categoriesList.length > 1">
              <div class="dish-name">All</div>
            </div>

            <div v-for="(data1, index) in this.menuList" :key="index">
              <img
                :src="getImage(stringConverg(index))"
                alt="category-image"
                class="categoryImg"
              />
              <div class="categoryDetail">
                <div class="categoryName">{{ index }}</div>
              </div>

              <div :id="stringConverg(index)">
                <div v-for="(data, k) in data1" :key="k">
                  <div class="meal-container" @click="addToCart(data)">
                    <div class="meal-wrapper">
                      <div class="meal-name">
                        {{data.menuItemId}}{{ data.menuItemId ? ". " : ""}}{{ data.name }}
                      </div>

                      <div class="meal-description">
                        {{ data.short_description }}
                      </div>
                      <div class="mb-2">
                        <span
                          class="meal-description"
                          style="font-weight: 500"
                          >{{
                            data.allergens.length != 0
                              ? "* " + alergen(data)
                              : ""
                          }}</span
                        >
                      </div>
                      <div class="meal-price">
                        {{ data.price.toFixed(2) }} €
                      </div>
                    </div>
                    <div class="meal-img">
                      <img :src="data.coverImage" alt="" />
                    </div>
                    <div class="meal-button"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Discount
        @addToCartDiscountItem="addToCartDiscountItem"
        v-if="discountcomponent"
      />
    </div>

    <Orders @subTotal="subTotal" :showButton="showButton" />
    <OrdersResposive @subTotal="subTotal" />
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
import { apiUrl } from "../api/services";
import $ from "jquery";
import { favRestaurant } from "../api/services";
import { clearOrders, validTimeForOrder } from "../global/helper";
import AboutRestaurant from "./Modals/AboutRestaurant";
import ReserveBooking from "./Modals/ReserveBooking";
import StaffTemperature from "./Modals/StaffTemperature";
import Orders from "../components/Oders";
import OrdersResposive from "../components/OrdersResposive";
import Discount from "../components/Discount";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import ImageLoader from "./ImageLoader";
import RecordNotFound from "./RecordNotFound";
import "hooper/dist/hooper.css";
import axios from "axios";
export default {
  name: "Restaurant-Detail",

  data() {
    return {
      count: 0,
      discountcomponent: false,
      showComponent: false,
      showStaffComponent: false,
      selected: 0,
      total_amount: 0,
      sub_total: 0,
      number: 1,
      isHidden: true,
      isShow: true,
      showButton: true,
      isFav: false,
      restaurant_detail: {},
      aboutRestaurant: {},
      todayTimeSlot: {},
      reviewsList: {},
      categories: [],
      menuList: [],
      categoriesList: [],
      orderList: [],
      staffTemperaturelList: [],
      product: {
        name: "Oninon Rings",
        price: "18.40",
        extra: "With extra garlic mayo",
      },
      hooperSettings: {
        wheelControl: false,
        itemsToSlide: 1,
        itemsToShow: 8,
        trimWhiteSpace: true,
      },
    };
  },
  methods: {
    alergen(data) {
      return data.allergens
        .map(({ identifier }) => "(" + identifier + ")")
        .join(" ");
    },

    favouriteRestaurant(object, fav) {
      let favData = {
        id: object.id,
      };
      let data = JSON.stringify({
        user_id: this.$store.state.loginUserData.id,
        restaurant_id: object.id,
        fav_flage: fav,
      });
      favRestaurant(data).then((responce) => {
        let users = this.$store.state.loginUserData;
        if (responce.code == 200) {
          users.favourite_restaurant.push(favData);
          this.$store.commit("updateLoginUser", users);
          localStorage.setItem("currentUser", JSON.stringify(users));
          this.renderHert(
            this.$store.state.loginUserData,
            this.$route.params.id
          );
        }
        if (responce.code == 201) {
          let index = users.favourite_restaurant.findIndex(
            (t) => t.id === parseInt(object.id)
          );
          if (index !== -1) {
            users.favourite_restaurant.splice(index, 1);
          }
          this.$store.commit("updateLoginUser", users);
          localStorage.setItem("currentUser", JSON.stringify(users));
          this.isFav = false;
        }
      });
    },

    getImage(value) {
      if (value == "suppen") {
        return "http://backend.herrlecker.de/Images/soup.jpeg";
      }
      if (value == "warme_vorspeisen") {
        return "http://backend.herrlecker.de/Images/warm.jpg";
      }
      if (value == "chefkoch_empfehlung") {
        return "http://backend.herrlecker.de/Images/warm.jpg";
      }
      if (value == "tandoori_snacks") {
        return "http://backend.herrlecker.de/Images/tandoori_snack.jpeg";
      }

      if (value == "enten_spezialitäten") {
        return "http://backend.herrlecker.de/Images/duck.jpeg";
      }
      if (value == "chicken_spezialitäten") {
        return "http://backend.herrlecker.de/Images/chicken.jpg";
      }
      if (value == "beef_spezialitäten") {
        return "http://backend.herrlecker.de/Images/beef.jpg";
      }
      if (value == "fisch_spezialitäten") {
        return "http://backend.herrlecker.de/Images/fish.png";
      }

      if (value == "vegan") {
        return "http://backend.herrlecker.de/Images/veg.jpg";
      }
      if (value == "lamm_spezialitäten") {
        return "http://backend.herrlecker.de/Images/mutton.jpg";
      }
      if (value == "biryani_spezialitäten") {
        return "http://backend.herrlecker.de/Images/biryani.jpg";
      }
      if (value == "vegetarische_spezialitäten") {
        return "http://backend.herrlecker.de/Images/veg.jpg";
      }

      if (value == "beilagen") {
        return "http://backend.herrlecker.de/Images/warm.jpg";
      }
      if (value == "salate") {
        return "http://backend.herrlecker.de/Images/salad.jpg";
      }
      if (value == "pasta_nudelgerichte") {
        return "http://backend.herrlecker.de/Images/pasta.jpg";
      }
      if (value == "pizza") {
        return "http://backend.herrlecker.de/Images/pizza.jpg";
      }

      if (value == "fischgerichte") {
        return "http://backend.herrlecker.de/Images/chicken.jpg";
      }
      if (value == "burger") {
        return "http://backend.herrlecker.de/Images/gal6.jpg";
      }
      if (value == "dessert") {
        return "http://backend.herrlecker.de/Images/dessert.jpg";
      }
      if (value == "biere") {
        return "http://backend.herrlecker.de/Images/white.jpg";
      }

      if (value == "indische_getränke") {
        return "http://backend.herrlecker.de/Images/indian_drinks.jpg";
      }
      if (value == "alkoholfreie_getränke") {
        return "http://backend.herrlecker.de/Images/alcoholfree.png";
      }
      if (value == "heibe_getranke") {
        return "http://backend.herrlecker.de/Images/hotdrink.jpg";
      }
      if (value == "schoppenweine") {
        return "http://backend.herrlecker.de/Images/white.jpg";
      }

      if (value == "rose") {
        return "http://backend.herrlecker.de/Images/white.jpg";
      }
      if (value == "rotweine") {
        return "http://backend.herrlecker.de/Images/red.jpg";
      }
      if (value == "prosecco_champagner") {
        return "http://backend.herrlecker.de/Images/champ.jpg";
      }
      if (value == "indian_food") {
        return "http://backend.herrlecker.de/Images/indian_food.jepg";
      }

      // code to use filterdData, presumably using this.setState somewhere
    },

    parameterdFilerArray(array, key, value) {
      const filteredData = array.filter((user) => user[key] === value);
      return filteredData;
      // code to use filterdData, presumably using this.setState somewhere
    },

    renderHert(users, id) {
      const filteredData = users.favourite_restaurant.filter((item) => {
        if (item.id === parseInt(id)) {
          this.isFav = true;
        }
      });
      return filteredData;
    },

    stringConverg(string) {
      var newStr = string.replace(" ", "_").toLowerCase();
      return newStr;
    },
    filterCategory: function (item) {
      this.discountcomponent = false;
      if (item === "deals") {
        this.discountcomponent = true;

        console.log("itemitem", item);
        this.selected = item;
        return false;
      }

      if (typeof item === "undefined") {
        this.selected = 0;
        this.menuList = this.restaurant_detail.menu_items_category_wise;
        $("html, body").animate(
          {
            scrollTop: $("." + "dish").offset().top - 268,
          },
          1000
        );

        return false;
      }
      $("html, body").animate(
        {
          scrollTop: $("#" + item).offset().top - 268,
        },
        1000
      );
      this.selected = item;

      // let newData = parameterdFilerArray(
      //   this.restaurant_detail.menu_items_category_wise,
      //   item,
      //   item
      // );

      // console.log(this.restaurant_detail.menu_items_category_wise[item]);

      // let filtered = this.restaurant_detail.menu_items_category_wise.filter(
      //   (t) => t.category.name === item
      // );
      // this.menuList = filtered;
    },

    settimeObject(dateFormat) {
      let date = dateFormat;
      let timeobject = {
        h: date.getHours(),
        m: date.getMinutes(),
      };
      return timeobject;
    },

    addToCart: function (item) {
      console.log(
        "this.$store.getters.restaurantDetails",
        this.$store.getters.restaurantDetails
      );
      if (
        !validTimeForOrder(
          this.$store.getters.restaurantDetails,
          this.settimeObject(new Date())
        )
      ) {
        this.$swal({
          text: this.$t("restaurant_close"),
          allowOutsideClick: false,
          closeOnClickOutside: false,
          allowEscapeKey: false,
        });
        return false;
      }
      let length = this.$store.getters.AllorderList.length;
      let restaurantId = this.$store.getters.restaurantId;
      if (length > 0 && restaurantId && this.$route.params.id != restaurantId) {
        this.$swal({
          title: "Cart",
          text: "Are you sure you want to add a new restaurant menu items?",
          allowOutsideClick: false,
          showCancelButton: true,
          closeOnClickOutside: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
            clearOrders();
            this.addMenuItem(item);
            return true;
          } else {
            return false;
          }
        });
        return false;
      }
      this.addMenuItem(item);
    },

    addMenuItem(item) {
      this.checkExistRestaurant();
      this.$store.dispatch("setRestaurantId", this.$route.params.id);
      if (this.$store.getters.AllorderList.length > 0) {
        this.orderList = this.$store.getters.AllorderList;
      }
      let index = this.orderList.findIndex((x) => x.menu_id === item.id);
      if (index == -1) {
        let menuitems = {
          menu_id: item.id,
          menu_item_name: item.name,
          price: item.price,
          total_price: item.price * 1,
          quantity: 1,
        };
        this.orderList.push(menuitems);
        this.$store.commit("newsetAddToCart", this.orderList);
        this.orderList = this.$store.getters.AllorderList.filter(
          (item) => item.menu_id
        );
        this.$store.dispatch("setMenuItems", this.orderList);
      } else {
        this.orderList[index].quantity += 1;
        this.orderList[index].total_price =
          this.orderList[index].price * this.orderList[index].quantity;
      }
      this.subTotal();
    },

    forPagination: function (page) {
      this.restaurantDetail(page);
    },
    addToCartDiscountItem: function (object) {
      if (
        !validTimeForOrder(
          this.$store.getters.restaurantDetails,
          this.settimeObject(new Date())
        )
      ) {
        this.$swal({
          text: this.$t("restaurant_close"),
          allowOutsideClick: false,
          closeOnClickOutside: false,
          allowEscapeKey: false,
        });
        return false;
      }
      let length = this.$store.getters.AllorderList.length;
      let restaurantId = this.$store.getters.restaurantId;
      if (length > 0 && restaurantId && this.$route.params.id != restaurantId) {
        this.$swal({
          title: "Cart",
          text: "Are you sure you want to add a new restaurant menu items?",
          allowOutsideClick: false,
          showCancelButton: true,
          closeOnClickOutside: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
            clearOrders();
            this.addOffers(object);
            return true;
          } else {
            return false;
          }
        });
        return false;
      }
      this.addOffers(object);
    },

    checkExistRestaurant() {
      let restaurantList = this.$store.getters.allSearchResturants.data;
      if (restaurantList) {
        let restaurant = restaurantList.find(
          (t) => t.id == this.$route.params.id
        );
        this.$store.dispatch("setorderRestaurant", restaurant);
      }
    },

    addOffers(object) {
      this.checkExistRestaurant();
      this.$store.dispatch("setRestaurantId", this.$route.params.id);
      if (this.$store.getters.AllorderList.length > 0) {
        this.orderList = this.$store.getters.AllorderList;
      }
      let index = this.orderList.findIndex((x) => x.id === object.id);
      if (index == -1) {
        let offers = {
          id: object.id,
          price: object.discount_price,
          menu_item_name: object.name,
          total_price: object.discount_price * 1,
          quantity: 1,
        };
        this.orderList.push(offers);
        this.$store.commit("newsetAddToCart", this.orderList);
        this.orderList = this.$store.getters.AllorderList.filter(
          (item) => item.id
        );
        this.$store.dispatch("setOffer", this.orderList);
      } else {
        this.orderList[index].quantity += 1;
        this.orderList[index].total_price =
          this.orderList[index].price * this.orderList[index].quantity;
      }
      this.subTotal();
    },

    subTotal: function () {
      this.sub_total = this.$store.getters.AllorderList.reduce(
        (acc, item) => acc + item.total_price,
        0
      );
      this.$store.commit("setSubTotal", this.sub_total);

      let totlaAmount = this.sub_total;
      let deliveryCost = this.restaurant_detail.addresses.delivery_cost;
      if (this.restaurant_detail.type == "Delivery") {
        this.$store.dispatch("setDeliveryAddress", "");
        this.$store.dispatch(
          "setDeliveryAddress",
          this.restaurant_detail.addresses
        );
        totlaAmount = this.sub_total + deliveryCost;
      }
      this.$store.commit("setTotalAmount", totlaAmount);
    },

    restaurantDetail: async function (page) {
      this.$store.commit("isLoading", true);
      try {
        if (typeof page === "undefined") {
          page = 1;
        }
        const data = {
          restaurant_id: this.$route.params.id,
          type: "website",
          postcode: this.$store.state.zipCodeSearch,
          page: page,
        };
        if (this.$route.params.id > 0) {
          const requestOne = axios.post(
            apiUrl + "customer/restaurant-detail",
            data
          );

          const requestTwo = axios.post(apiUrl + "restaurant/reviews", data);
          const staffTemperature = axios.post(
            apiUrl + "staff_temperature/list",
            data
          );

          const menuItemCategoryWise = axios.post(
            apiUrl + "customer/menu-list-category-wise",
            data
          );

          const discountList = axios.post(
            apiUrl + "customer/discount-list",
            data
          );

          await axios
            .all([
              requestOne,
              requestTwo,
              discountList,
              menuItemCategoryWise,
              staffTemperature,
            ])
            .then(
              await axios.spread((...responses) => {
                const responseOne = responses[0];
                const responseTwo = responses[1];
                const discountList = responses[2];
                const menuItemCategoryWise = responses[3];
                const staffTemperature = responses[4];

                if (responseTwo.data.code == 200) {
                  this.reviewsList = responseTwo.data.data;
                }

                if (staffTemperature.data.code == 200) {
                  this.staffTemperaturelList = staffTemperature.data.data;
                }

                if (discountList.data.code == 200) {
                  this.$store.commit("setdiscountList", discountList.data.data);
                }

                if (responseOne.data.code == 200) {
                  console.log("setResturantsDetails", responseOne.data.data);
                  this.$store.commit(
                    "setResturantsDetails",
                    responseOne.data.data
                  );

                  this.aboutRestaurant = responseOne.data.data;

                  this.restaurant_detail = menuItemCategoryWise.data.data;
                  let currentDate = new Date();
                  const day_name = currentDate.toLocaleDateString("default", {
                    weekday: "long",
                  });
                  const [, current_time] = currentDate.toLocaleDateString("en-us",
                      {
                        hourCycle: "h23",
                        hour: "2-digit",
                        minute: "2-digit"
                      }).split(", ");

                  let timeSlots = responseOne.data.data.timeSlots;
                  let filtered = timeSlots.find((t) => t.day === day_name);

                  let lunch_start_time = filtered.lunch_start_time <= current_time;
                  let lunch_end_time = filtered.lunch_end_time >= current_time;
                  let dinner_start_time = filtered.dinner_start_time <= current_time;
                  let dinner_end_time = filtered.dinner_end_time >= current_time;

                  // (filtered.status == 0) ? this.todayTimeSlot = false : this.todayTimeSlot = filtered;

                  ((filtered.status == 1) && ( (lunch_start_time && lunch_end_time) || (dinner_start_time && dinner_end_time) )) ?
                      this.todayTimeSlot = filtered : this.todayTimeSlot = false;

                  // this.todayTimeSlot = filtered;

                  this.menuList =
                    this.restaurant_detail.menu_items_category_wise;

                  this.categoriesList =
                    this.restaurant_detail.menu_items_category_wise;

                  const arrayUniqueByKey =
                    responseOne.data.data.meunItems.reduce((seed, current) => {
                      return Object.assign(seed, {
                        [current.category.name]: current.category,
                      });
                    }, {});

                  this.categories = arrayUniqueByKey;
                  this.showComponent = true;
                  this.showStaffComponent = true;
                  this.$store.commit("isLoading", false);
                } else {
                  console.log(
                    "response.data.message",
                    responseOne.data.message
                  );
                }
              })
            );
        } else {
          if (this.zipcode.length == 0) {
            console.log("zipcode length not correct");
          }
        }
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
          this.buttonloader = false;
        }
      }
    },
  },
  computed: {
    setsearchBarText() {
      return this.$store.state.loginUserData;
    },
  },

  watch: {
    setsearchBarText() {
      console.log("sdf");
    },
  },
  mounted() {
    this.restaurantDetail();
    // console.log("time"+this.todayTimeSlot);
    this.renderHert(this.$store.state.loginUserData, this.$route.params.id);
  },
  components: {
    AboutRestaurant,
    ReserveBooking,
    StaffTemperature,
    Hooper,
    Slide,
    HooperNavigation,
    Orders,
    Discount,
    RecordNotFound,
    ImageLoader,
    OrdersResposive,
    StarRating,
  },

};
</script>

<style scoped>
.info-btn-wrapper.temp {
  padding: 0 25px 10px;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (max-width: 576px) {
  .info-btn-wrapper.temp {
    padding: 0 15px 10px;
  }
}
.btn-wrapper {
  padding: 0px 25px 10px;
  text-align: right;
}
.categoryImg {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.categoryDetail {
  background-color: #f8f5f2;
  padding: 10px 15px;
}

.categoryDetail .categoryName {
  font-size: 18px;
  font-weight: 600;
  color: rgb(10, 56, 71);
}

.categoryDetail .categoryDesc {
  font-size: 12px;
  font-weight: 400;
  color: rgb(10, 56, 71);
}
@media only screen and (max-width: 412px) {
  #restaurant-detail .info-btn-wrapper {
    flex-direction: column;
    row-gap: 5px;
    /*align-items: flex-end;*/
  }
}
</style>
