<template>
   <div class="custom-container py-5">
      <div class="row">
         <div class="col-12">
            <h1>{{
                $t("terms_of_use_heading")
              }}</h1>
            <p>{{
                $t("terms_of_use_desc")
              }}</p>
            <h2>1. {{
                $t("definitions")
              }}</h2>
            <p><strong>{{
                $t("offer")
              }}:</strong> {{
                $t("offer_desc")
              }}</p>
            <p><strong>{{$t("order")}}:</strong> {{$t("order_desc")}}</p>
            <p><strong>{{$t("customer_heading")}}:</strong> {{$t("customer_desc")}}</p>
            <p><strong>{{$t("agreement")}}:</strong> {{$t("agreement_desc")}}</p>
            <p><strong>{{$t("platform")}}:</strong> {{$t("platform_desc")}}</p>
            <p><strong>{{$t("restaurant")}}:</strong> {{$t("restaurant_desc")}}</p>
            <p><strong>{{$t("restaurant_information")}}:</strong> {{$t("restaurant_info_desc")}}</p>
            <p><strong>{{$t("Service")}}:</strong> {{$t("service_desc")}}</p>
            <p><strong>{{$t("takeaway")}}:</strong> {{$t("takeaway_desc")}}</p>
            <p><strong>{{$t("tip")}}:</strong> {{$t("tip_desc")}}</p>
            <h2>{{$t("identity_of_takeaway")}}</h2>
            <p>{{$t("takeaway_operating")}}<br> <br>{{$t("address")}}:
               <br>
               <br>Oosterdoksstraat 80
               <br>1011 DK Amsterdam
               <br>The Netherlands
               <br>CoC: 64473716
               <br><br>{{$t("correspondence_address")}}
               <br>yd. yourdelivery GmbH
               <br>Cuvrystr. 50, 52, 54 / Schlesische Str. 34,
               <br>10997 Berlin
               <br><br>E-Mail: info@tajtrudering.de
               <br><br>Tel: 030 837 96 000 (available Mondays until Wednesdays from 10.00h until 23.00h. Thursdays until
               Sundays from 10.00h until 00.00h).
               <br>Fax: 0800 202 07 702</p>
            <h2>3. {{$t("applicability")}}</h2>
            <ol>
               <li>{{$t("applicability_point_1")}}</li>
               <li>{{$t("applicability_point_2")}}</li>
               <li>{{$t("applicability_point_3")}}</li>
            </ol>
            <h2>4. {{$t("the_offer")}}</h2>
            <ol>
               <li>{{$t("the_offer_point_1")}}</li>
               <li>{{$t("the_offer_point_2")}}</li>
            </ol>
            <h2>5. {{$t("the_agreement")}}</h2>
            <ol>
               <li>{{$t("the_agreement_point_1")}}</li>
               <li>{{$t("the_agreement_point_2")}}</li>
               <li>{{$t("the_agreement_point_3")}}</li>
               <li>{{$t("the_agreement_point_4")}}</li>
               <li>{{$t("the_agreement_point_5")}}</li>
               <li>{{$t("the_agreement_point_6")}}</li>
               <li>{{$t("the_agreement_point_7")}}</li>
               <li>{{$t("the_agreement_point_8")}}</li>
               <li>{{$t("the_agreement_point_9")}}</li>
               <li>{{$t("the_agreement_point_10")}}</li>
               <li>{{$t("the_agreement_point_11")}}</li>
               <li>{{$t("the_agreement_point_12")}}</li>
               <li>{{$t("the_agreement_point_13")}}</li>
            </ol>
            <h2>6.{{$t("dissolution_of_the_agreement")}}
            </h2>
            <ol>
               <li>{{$t("dissolution_of_the_agreement_point_1")}}</li>
               <li>{{$t("dissolution_of_the_agreement_point_2")}}</li>
               <li>{{$t("dissolution_of_the_agreement_point_3")}}</li>
               <li>{{$t("dissolution_of_the_agreement_point_4")}}</li>
               
            </ol>
            <h2>7. {{$t("withdrawal")}}</h2>
            <ol>
               <li>{{$t("withdrawal_point_1")}}
               </li>
               <ul>
                  <li>{{$t("withdrawal_point_1_1")}}</li>
                  <li>{{$t("withdrawal_point_1_2")}}</li>
                  <li>{{$t("withdrawal_point_1_3")}}</li>
                  <li>{{$t("withdrawal_point_1_4")}}</li>
               </ul>
               <li>{{$t("withdrawal_point_2")}}</li>
               <div class="box">
                  <p class="text-center"><strong>{{$t("cancellation_box")}}</strong></p>
                  <p><strong>{{$t("cancellation_box_heading_1")}}</strong>
                     <br>{{$t("cancellation_box_1_decs_1")}}
                     <br><br>{{$t("cancellation_box_1_decs_2")}}
                     <br><br>{{$t("cancellation_box_1_decs_3")}}
                     <br><br>{{$t("cancellation_box_1_decs_4")}}
                     <br><br><strong>{{$t("cancellation_box_heading_2")}}</strong>
                     <br>{{$t("cancellation_box_2_decs_1")}}
                     <br><br>{{$t("cancellation_box_2_decs_2")}}
                     <br><br>{{$t("cancellation_box_2_decs_3")}}
                     <br><br>{{$t("cancellation_box_2_decs_4")}}
                  </p>
               </div>
               <div class="box">
                  <p class="text-center"><strong>{{$t("cancellation_box_heading_3")}}</strong>
                  </p>
                  <p>{{$t("cancellation_box_3_decs_1")}}
                     <ul>
                        <li>{{$t("cancellation_box_3_decs_2")}}
                        </li>
                        <li>{{$t("cancellation_box_3_decs_3")}}
                        </li>
                        <li>{{$t("cancellation_box_3_decs_4")}}
                        </li>
                        <li>{{$t('cancellation_box_3_decs_5')}} (s)
                        </li>
                      
                        <li>{{$t('cancellation_box_3_decs_6')}}</li>
                        <li>{{$t('date')}}</li>
                     </ul>
                     (*) {{$t('cancellation_box_3_decs_7')}}
                  </p>
               </div>
            </ol>
            <h2>8. {{$t('payment')}}</h2>
            <ol>
               <li>{{$t('payment_point_1')}}</li>
               <li>{{$t('payment_point_2')}}</li>
               <li>{{$t('payment_point_3')}}</li>
              
            </ol>
            <h2>9. {{$t('complaints')}}
            </h2>
            <ol>
               <li>{{$t('complaints_point_1')}}</li>
               <li>{{$t('complaints_point_2')}}</li>
               <li>{{$t('complaints_point_3')}}</li>
               <li>{{$t('complaints_point_4')}}</li>
               <li>{{$t('complaints_point_5')}}</li>
               <li>{{$t('complaints_point_6')}}</li>
              
            </ol>
            <h2>10. {{$t('newletter')}}
            </h2>
            <ol>
               <li>{{$t('newletter_point_1')}}</li>
            </ol>
            <h2>11. {{$t('reviews_of_the_restaurant')}}
            </h2>
            <ol>
               <li>{{$t('reviews_of_the_restaurant_point_1')}}</li>
               
            </ol>
            <h2>12.{{$t('inspection_and_correction')}}
            </h2>
            <p>{{$t('inspection_and_correction_desc')}}

            </p>
            <h2>13. {{$t('takeaway_liability')}}
            </h2>
            <ol>
               <li>{{$t('takeaway_liability_point_1')}}</li>
               <li>{{$t('takeaway_liability_point_2')}}</li>
               <ol class="letter">
                  <li>{{$t('takeaway_liability_point_2_1')}}</li>
                  <li>{{$t('takeaway_liability_point_2_2')}}</li>
                  <li>{{$t('takeaway_liability_point_2_3')}}</li>
                  <li>{{$t('takeaway_liability_point_2_4')}}</li>
                  <li>{{$t('takeaway_liability_point_2_5')}}</li>
                  <li>{{$t('takeaway_liability_point_2_6')}}</li>
                  <li>{{$t('takeaway_liability_point_2_7')}}</li>
                
               </ol>
            </ol>
         </div>
      </div>
   </div>
</template>

<script>
   export default {
      name: 'TermsOfUse'
   }
</script>

<style>
   .custom-container {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
      max-width: 960px;
   }

   .custom-container h1 {
      font-size: 28px;
      font-weight: 400;
      color: #666;
      margin-bottom: 10px;
   }

   .custom-container h2 {
      font-size: 21px;
      font-weight: 400;
      color: #666;
      margin-bottom: 10px;
      border-bottom: 1px solid #ccc;
      line-height: 32px;
      padding-top: 20px;
   }

   .custom-container p{
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
      color: #666;
      margin-bottom: 15px;
   }

   .custom-container ol {
      padding-inline-start: 15px;
   }

   .custom-container ol li {
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
      color: #666;
   }

   .custom-container p strong {
      font-weight: 700;
   }

   .custom-container .box {
      border: 1px solid #000;
      padding: 15px;
      margin: 15px 0;
   }

   .letter li {
      list-style-type: lower-latin;
   }
</style>