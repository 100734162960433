<template>
  <div>
    <div class="container-fluid py-4">
      <div class="v-toolbar">
        <div class="leftSidebar">
          <div class="restaurant-count pb-4">
            <button
              class="btn btn-success btn-sm mb-3 mt-1"
              @click="allRestaurants"
            >
              {{ $t("all_restaurant") }}</button
            ><br />
            {{ this.$store.state.restaurantLists.pagination.total }}
            {{ $t("restaurants") }}
          </div>
          <div class="restaurant-count pb-2">{{ $t("select") }}</div>
          <div class="d-flex pb-4">
            <button
              v-bind:class="['select-btn', { active: selected == 1 }]"
              @click="typeFiler(1)"
            >
              {{ $t("delivery") }}
            </button>
            <button
              v-bind:class="['select-btn', { active: selected == 0 }]"
              @click="typeFiler(0)"
            >
              {{ $t("pickup") }}
            </button>
          </div>
          <div class="restaurant-count pb-2">
            {{ $t("review") }}
            <button class="btn btn-success btn-sm ml-4" @click="ratingClear">
              {{ $t("clear") }}
            </button>
          </div>

          <star-rating
            @rating-selected="ratingFilter"
            :star-size="20"
            v-model="initialRating"
            active-color="#5cb700"
          />
        </div>
        <div class="rightContent">
          <ImageLoader v-if="isLoader" />
          <RecordNotFound
            v-if="this.$store.getters.allSearchResturants.data.length < 1"
          />
          <RestaurantCard
            v-if="!isLoader"
            :listing="this.$store.getters.allSearchResturants"
          />
          <pagination
            :data="this.$store.getters.allSearchResturants.pagination"
            :limit="4"
            align="center"
            @pagination-change-page="getResultsByPagination"
          >
          </pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RestaurantCard from "../components/RestaurantCard";
import StarRating from "vue-star-rating";
import ImageLoader from "./ImageLoader";
import RecordNotFound from "./RecordNotFound";

import axios from "axios";
import { apiUrl } from "../api/services";

export default {
  name: "RestaurantListing",
  props: {
    isLoader: {
      type: Boolean,
    },
  },
  data() {
    return {
      selected: this.$store.getters.restaurantType,
      initialRating: 0,
      checked: "no",
      listing: this.$store.state.restaurantLists,
    };
  },
  mounted() {
    console.log(this.$store.state.zipCodeSearch);
  },
  components: {
    RestaurantCard,
    StarRating,
    RecordNotFound,
    ImageLoader,
  },

  methods: {
    ratingFilter: function (rating) {
      this.$emit("ratingFilter", rating);
    },
    ratingClear: function () {
      this.initialRating = 0.0;
      this.$emit("ratingFilter", "");
    },
    allRestaurants: function () {
      this.selected = -1;
      this.$emit("typeFiler", -1);
      this.$store.commit("setZipCodeSearch", "");
      this.$emit("allRestaurants", "");
    },
    typeFiler: function (type) {
      this.selected = type;
      this.$emit("typeFiler", type);
    },
    getResultsByPagination(page = 1) {
      const data = {
        sort_by: "",
        per_page: "10",
        page: page,
        postcode: this.$store.state.zipCodeSearch,
        category_id: "",
        type: this.$store.getters.restaurantType,
      };
      axios
        .post(apiUrl + "customer/zipbase-restaurant", data)
        .then((response) => {
          this.$store.commit("setSearchResturants", response.data.data);
          this.isLoader = false;
        });
    },
  },
};
</script>

<style scoped>
.content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1.5rem;
}
</style>
