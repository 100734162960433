<template>
  <div>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="footer-links">
              <router-link to="/recommend">{{
                $t("recommend_restaurant")
              }}</router-link>
              <router-link to="/signup">{{
                $t("sign_up_restaurant")
              }}</router-link>

              <router-link to="/privacy-statement">{{
                $t("privacy_statement")
              }}</router-link>
              <router-link to="/colophan">{{
                $t("address_footer")
              }}</router-link>
              <router-link to="/cookie-statement">{{
                $t("cookies_statement")
              }}</router-link>
              <router-link to="/alergies">{{ $t("alergies") }}</router-link>
              <div class="copyright">© 2022 Herr lecker</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>
