<template>
  <div class="left-content">
    <div class="basket-label">{{ $t("basket") }}</div>
    <div class="product-row-wrapper">
      <div
        class="product-row"
        v-for="(list, index) in this.$store.getters.AllorderList"
        :key="index"
      >
        <div class="product-quantity">{{ list.quantity }} x</div>
        <div class="product-name">{{ list.menu_item_name }}</div>
        <div class="product-btn-wrapper">
          <button class="product-buttons" @click="decrement(index)">
            <i class="fas fa-minus"></i>
          </button>
          <button class="product-buttons" @click="increment(index)">
            <i class="fas fa-plus"></i>
          </button>
          <!-- <button class="product-edit" v-on:click="isHidden = !isHidden">
              <i class="fas fa-pencil-alt pl-2" style="color: #5cb700"></i>
            </button> -->
        </div>
        <div class="product-price">{{ list.total_price.toFixed(2) }} €</div>
        <div class="trash-icon">
          <i class="far fa-trash-alt" @click="spliceOrder(index)"></i>
        </div>
      </div>

      <div v-if="this.$store.getters.AllorderList.length == 0">
        <div class="text-center pt-3">
          <i
            class="fas fa-shopping-bag"
            style="font-size: 32px; color: #d7d7d7"
          ></i>
        </div>
        <div class="basket-text py-3">
          {{ $t("tasty_food") }}
        </div>
      </div>

      <div class="cart-text">
        <div class="d-flex justify-content-between grey pb-2">
          <span>{{ $t("sub_total") }}</span>
          <span>{{ this.$store.getters.sub_total.toFixed(2) }} €</span>
        </div>
        <div
          v-if="this.$store.getters.voucher"
          class="d-flex justify-content-between grey pb-2"
        >
          <span>Voucher ID {{ this.$store.getters.voucher.unique_code }}</span>
          <span>{{ this.voucher_discount.toFixed(2) }} €</span>
        </div>

        <div class="d-flex justify-content-between grey pb-2">
          <span>{{ $t("delivery_charges") }}</span>
          <span
            >{{
              this.$store.getters.AllorderList.length == 0
                ? 0
                : this.$store.getters.delivery_address.delivery_cost
                ? this.$store.getters.delivery_address.delivery_cost.toFixed(2)
                : 0
            }}
            €</span
          >
        </div>
        <div class="d-flex justify-content-between text-bold">
          <span>{{ $t("total") }}</span>
          <span
            >{{
              this.$store.getters.AllorderList.length == 0
                ? 0
                : (
                    this.$store.getters.total_amount - this.voucher_discount
                  ).toFixed(2)
            }}
            €</span
          >
        </div>
      </div>
      <router-link to="/checkout">
        <button class="order-btn" v-if="showButton">
          {{ $t("check_out") }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Listing",
  props: {
    showButton: {
      type: Boolean,
    },
  },
  data() {
    return {
      orderList: [],
      voucher_discount: 0,
    };
  },

  mounted() {},

  methods: {
    increment(index) {
      this.orderList = this.$store.getters.AllorderList;
      this.orderList[index].quantity += 1;
      this.orderList[index].total_price =
        this.orderList[index].price * this.orderList[index].quantity;
      this.$store.commit("newsetAddToCart", this.orderList);
      this.subTotal();
    },

    subTotal: function () {
      this.sub_total = this.$store.getters.AllorderList.reduce(
        (acc, item) => acc + item.total_price,
        0
      );
      this.$store.commit("setSubTotal", this.sub_total);
      let totlaAmount = this.sub_total;
      if (this.$store.getters.restaurantDetails.type == "Delivery") {
        let restaurant_detail = this.$store.getters.restaurantDetails;
        let deliveryCost = restaurant_detail.addresses.delivery_cost;
        this.$store.dispatch("setDeliveryAddress", "");
        this.$store.dispatch("setDeliveryAddress", restaurant_detail.addresses);
        totlaAmount = this.sub_total + deliveryCost;
      }
      this.$store.commit("setTotalAmount", totlaAmount);
    },

    decrement(index) {
      this.orderList = this.$store.getters.AllorderList;
      if (this.orderList[index].quantity == 1) {
        this.orderList[index].quantity = 1;
        this.$store.commit("newsetAddToCart", this.orderList);
        this.subTotal();
        return false;
      }
      this.orderList[index].quantity -= 1;
      this.orderList[index].total_price -= this.orderList[index].price;
      this.$store.commit("newsetAddToCart", this.orderList);
      this.subTotal();
    },
    removeDeliveryCost() {
      var totalAmount = 0;
      let deliveryCost = this.$store.getters.delivery_address.delivery_cost
        ? this.$store.getters.delivery_address.delivery_cost
        : 0;
      totalAmount =
        parseFloat(this.$store.getters.total_amount) - parseFloat(deliveryCost);
      return totalAmount;
    },

    spliceOrder: function (index) {
      this.orderList = this.$store.getters.AllorderList;
      if (index !== -1) {
        this.orderList.splice(index, 1);
      }

      let menuItems = this.$store.getters.AllorderList.filter(
        (item) => item.menu_id
      );

      this.$store.dispatch("setMenuItems", menuItems);

      let offers = this.$store.getters.AllorderList.filter((item) => item.id);
      this.$store.dispatch("setOffer", offers);
      this.subTotal();
    },
  },
  components: {},
  watch: {
    setVoucher() {
      if (this.$store.getters.voucher) {
        this.voucher_discount = this.$store.getters.voucher.voucher_discount;
      }
    },
  },
  computed: {
    setVoucher() {
      return this.$store.getters.voucher;
    },
  },
};
</script>
