<template>
  <table class="table table-bordered">
    <thead class="thead">
      <tr>
        <th>Offer Name</th>
        <th>Quantity</th>
        <th>offer Type</th>
        <th>Actual Price</th>
        <th>Discounted price</th>
        <th>Total price</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(discountedItem, key) in this.discountedItems" :key="key">
        <td>
          {{ discountedItem.offername }}
        </td>
        <td>
          {{ discountedItem.quantity }}
        </td>
        <td>
          {{ discountedItem.offerType }}
        </td>
        <td>
          {{ discountedItem.actual_price }}
        </td>
        <td>
          {{ discountedItem.discountedPrice }}
        </td>
        <td>
          {{ discountedItem.total_price }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  components: {},
  props: {
    discountedItems: {},
  },
  data() {
    return {
      checkedNames: [],
    };
  },

  mounted() {
    console.log(this.discountedItems);
  },

  methods: {},
};
</script>
