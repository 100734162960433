<template>
  <div>
    <div class="position-relative">
      <section class="top-banner">
        <div class="restaurant-number">{{ $t("order_takeaway_in") }}</div>
        <div class="search-input mt-4">
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <i class="fas fa-map-marker-alt"></i>
            </b-input-group-prepend>
            <b-form-input
              type="search"
              :placeholder="$t('search_plach_holder')"
            ></b-form-input>
            <router-link to="/delivery"
              ><button class="show-btn">{{ $t("search") }}</button></router-link
            >
          </b-input-group>
        </div>
      </section>
    </div>
    <div class="text">{{ $t("my_account") }}</div>
    <b-tabs pills vertical class="accountTabs">
      <b-tab
        :title="$t('orders')"
        v-if="this.$store.state.isAuthenticated"
        active
      >
        <OrderListing
          :listing="orders"
          @orderPagination="orderPagination"
          v-if="orderShow"
        />
      </b-tab>

      <b-tab
        :title="$t('reserve_booking')"
        v-if="this.$store.state.isAuthenticated"
      >
        <b-tabs style="padding: 0px !important" pills card>
          <b-tab :title="$t('upcoming')" active @click="changeTab('upcoming')">
          </b-tab>
          <b-tab
            :title="$t('history')"
            @click="changeTab('history')"
            class="reviewTab"
          >
            <div class="px-3"></div>
          </b-tab>
        </b-tabs>
        <ReserveBooking
          @bookingPagination="bookingPagination"
          :listing="booking"
          v-if="orderShow"
        />
      </b-tab>

      <b-tab :title="$t('voucher')" v-if="this.$store.state.isAuthenticated">
        <b-tabs style="padding: 0px !important" pills card>
          <b-tab
            active
            :title="$t('issued')"
            @click="changeTabVoucher('issued')"
            class="reviewTab"
          >
            <div class="px-3"></div>
          </b-tab>
          <b-tab :title="$t('used')" @click="changeTabVoucher('used')"> </b-tab>

          <b-tab
            :title="$t('expire')"
            @click="changeTabVoucher('expire')"
            class="reviewTab"
          >
            <div class="px-3"></div>
          </b-tab>
        </b-tabs>
        <VoucherListing
          @voucherPagination="voucherPagination"
          :listing="voucher"
          v-if="voucherShow"
        />
      </b-tab>

      <b-tab :title="$t('favourites')" v-if="this.$store.state.isAuthenticated">
        <Favourites
          :listing="restaurantList"
          @favPagination="favPagination"
          v-if="favShow"
        />
      </b-tab>
      <b-tab
        :title="$t('address_footer')"
        v-if="this.$store.state.isAuthenticated"
      >
        <Addresses />
      </b-tab>
      <b-tab :title="$t('personal_data')">
        <PersonalData />
      </b-tab>
      <b-tab
        v-if="this.$store.state.loginUserData.loginFrom == 'simple'"
        :title="$t('change_passowrd')"
      >
        <UpdatePassword />
      </b-tab>
      <b-tab
        v-if="this.$store.state.isAuthenticated"
        @click="logout"
        :title="$t('logout')"
      ></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Favourites from "../components/Favourites";
import { favRestaurantList } from "../api/services";
import { orderListing } from "../api/services";
import { reserveBooking } from "../api/services";
import { getUserVoucher } from "../api/services";
import OrderListing from "../components/OrderListing";
import ReserveBooking from "../components/ReserveBooking";
import VoucherListing from "../components/VoucherListing";
import Addresses from "../components/Addresses";
import PersonalData from "../components/PersonalData";
import UpdatePassword from "../components/UpdatePassword";
export default {
  name: "MyAccount",
  data() {
    return {
      addresses: this.$store.state.loginUserData.addresses,
      restaurantList: {},
      orders: {
        data: {},
        voucher: {},
        pagination: {},
      },
      booking: {},
      voucher: {},
      favShow: false,
      orderShow: false,
      voucherShow: false,
      tab: "upcoming",
      voucherTab: "issued",
    };
  },
  computed: {
    setsearchBarText() {
      return this.$store.state.loginUserData;
    },
  },

  watch: {
    setsearchBarText() {
      this.favRestaurantList();
      this.orderListing();
      this.reserveBooking();
    },
  },
  mounted() {
    this.favRestaurantList();
    this.reserveBooking();
    this.orderListing();
    this.voucherList();
  },

  components: {
    Favourites,
    Addresses,
    PersonalData,
    ReserveBooking,
    UpdatePassword,
    OrderListing,
    VoucherListing,
  },
  methods: {
    logout() {
      this.$store.commit("updateLoginUser", "");
      localStorage.setItem("isAuthenticated", false);
      localStorage.removeItem("currentUser");
      this.$store.commit("isLogin", false);
      localStorage.removeItem("token");
      this.$router.push({ path: "/delivery" });
    },

    changeTab(key) {
      this.tab = key;
      this.reserveBooking();
    },

    changeTabVoucher(key) {
      this.voucherTab = key;
      this.voucherList();
    },

    favRestaurantList() {
      let apiData = JSON.stringify({
        per_page: 10,
        page: 1,
        user_id: this.$store.state.loginUserData.id,
      });
      favRestaurantList(apiData).then((responce) => {
        console.log(responce);
        if (responce.code == 200) {
          this.favShow = true;
          this.restaurantList = responce.data;
        } else {
          console.log("sdf");
        }
      });
    },

    orderPagination(data) {
      let apiData = JSON.stringify({
        per_page: data.per_page,
        page: data.page,
      });
      orderListing(apiData).then((responce) => {
        if (responce.code == 200) {
          this.orderShow = true;
          this.orders = responce.data;
        }
      });
    },
    bookingPagination(data) {
      let apiData = JSON.stringify({
        per_page: 10,
        page: data.page,
        pagination: true,
        type: "device",
        tab: "all",
        email: this.$store.state.loginUserData.email,
      });
      reserveBooking(apiData).then((responce) => {
        if (responce.code == 200) {
          this.orderShow = true;
          this.booking = responce.data;
          console.log("this.booking", this.booking);
        } else {
          console.log(responce);
        }
      });
    },

    voucherPagination(data) {
      let apiData = JSON.stringify({
        per_page: 10,
        page: data.page,
        pagination: true,
        type: "device",
        tab: this.voucherTab,
        email: this.$store.state.loginUserData.email,
      });
      getUserVoucher(apiData).then((responce) => {
        if (responce.code == 200) {
          this.voucherShow = true;
          this.voucher = responce.data;
        } else {
          console.log(responce);
        }
      });
    },

    favPagination(data) {
      let apiData = JSON.stringify({
        per_page: data.per_page,
        page: data.page,
        user_id: this.$store.state.loginUserData.id,
      });
      favRestaurantList(apiData).then((responce) => {
        console.log(responce);
        if (responce.code == 200) {
          this.orderShow = true;
          this.orders = responce.data;
        } else {
          console.log("sdf");
        }
      });
    },

    orderListing() {
      let apiData = JSON.stringify({
        per_page: 10,
        page: 1,
        user_id: this.$store.state.loginUserData.id,
      });
      orderListing(apiData).then((responce) => {
        if (responce.code == 200) {
          this.orderShow = true;
          this.orders = responce.data;
          console.log("this.orders", this.orders);
        } else {
          console.log("sdf");
        }
      });
    },

    reserveBooking() {
      let apiData = JSON.stringify({
        per_page: 10,
        pagination: true,
        page: 1,
        type: "device",
        tab: this.tab,
        email: this.$store.state.loginUserData.email,
      });
      reserveBooking(apiData).then((responce) => {
        if (responce.code == 200) {
          this.orderShow = true;
          this.booking = responce.data;
        } else {
          console.log(responce);
        }
      });
    },

    voucherList() {
      let apiData = JSON.stringify({
        per_page: 10,
        pagination: true,
        page: 1,
        type: "device",
        tab: this.voucherTab,
        user_id: this.$store.state.loginUserData.id,
      });
      getUserVoucher(apiData).then((responce) => {
        if (responce.code == 200) {
          this.voucherShow = true;
          this.voucher = responce.data;
        } else {
          console.log(responce);
        }
      });
    },
  },
};
</script>

<style scoped>
.top-banner {
  flex-direction: column;
}

.search-input {
  width: 50%;
}

.input-group-prepend {
  background-color: #fff;
}

.search-input .form-control {
  background-color: #fff !important;
}

.text {
  font-size: 20px;
  color: #0a3847;
  font-weight: 400;
  line-height: 50px;
  padding: 25px 0 0 25px;
}
</style>
