<template>
  <div class="custom-container py-5">
    <div class="row">
      <div class="col-12">
        <h1>
          <b> {{ $t("privacy_statement_top_h") }}</b>
        </h1>
        <h1>
          <b> 1 {{ $t("privacy_statement_h_1") }}</b>
        </h1>
        <br />
        <p v-html="$t('privacy_statement_h_1_desc')"></p>
        <br />
        <h1>
          <b> 2 {{ $t("privacy_statement_h_2") }}</b>
        </h1>
        <br />
        <p v-html="$t('privacy_statement_h_2_desc')"></p>
        <br />
        <h1>
          <b> 3 {{ $t("privacy_statement_h_3") }}</b>
        </h1>
        <br />
        <p v-html="$t('privacy_statement_h_3_desc')"></p>

        <br />
        <h1>
          <b> 5 {{ $t("privacy_statement_h_5") }}</b>
        </h1>
        <br />
        <p v-html="$t('privacy_statement_h_5_desc')"></p>

        <br />
        <h1>
          <b> 6 {{ $t("privacy_statement_h_6") }}</b>
        </h1>
        <br />
        <p v-html="$t('privacy_statement_h_6_desc')"></p>

        <br />
        <h1>
          <b> 7 {{ $t("privacy_statement_h_7") }}</b>
        </h1>
        <br />
        <p v-html="$t('privacy_statement_h_7_desc')"></p>

        <br />
        <h1>
          <b> 8 {{ $t("privacy_statement_h_8") }}</b>
        </h1>
        <br />
        <p v-html="$t('privacy_statement_h_8_desc')"></p>

        <br />
        <h1>
          <b> {{ $t("privacy_statement_h_9") }}</b>
        </h1>
        <br />
        <p v-html="$t('privacy_statement_h_9_desc')"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyStatement",
};
</script>

<style>
.custom-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 960px;
}

.custom-container h1 {
  font-size: 28px;
  font-weight: 400;
  color: #666;
  margin-bottom: 10px;
}

.custom-container h2 {
  font-size: 21px;
  font-weight: 400;
  color: #666;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  line-height: 32px;
  padding-top: 20px;
}

.custom-container p {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  color: #666;
  margin-bottom: 15px;
}

.custom-container ol,
ul {
  padding-inline-start: 20px;
}

.custom-container ol li,
.custom-container ul li {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  color: #666;
}

.custom-container p strong {
  font-weight: 700;
}

.custom-container .box {
  border: 1px solid #000;
  padding: 15px;
  margin: 15px 0;
}

.letter li {
  list-style-type: lower-latin;
}
</style>
