<template>
  <div class="content">
    <div
      @click="redirectDetail(list.id)"
      class="restaurantCard"
      v-for="(list, index) in listing.data"
      :key="index"
    >
      <div class="topWrapper">
        <div class="coverImg">
          <img :src="list.coverImage" alt="" />
        </div>
        <div class="profileImg">
          <img :src="list.profile_image" alt="" />
        </div>
      </div>
      <div class="bottomWrapper">
        <star-rating
          :read-only="true"
          v-bind:increment="0.5"
          v-bind:max-rating="5"
          :rating="list.rating"
          inactive-color="#666"
          active-color="#5CB700"
          v-bind:star-size="12"
        >
        </star-rating>
        <div class="restaurant-name pt-2">{{ list.name }}</div>
        <div class="kitchen pt-1">
          <div class="pr-1">
            <b>{{  list.restaurantId}}</b>
          </div>
          <div class="pr-1">
<!--            <b v-if="openingClosingTime(list)" class="btn btn-link" style="font-weight: bold; padding: unset; font-size: unset; color: #28a745" v-b-modal.about-restaurant @click="handleModalEventIgnore(list.id)">Timings</b>-->
            <b v-if="openingClosingTime(list)" style="font-weight: bold; padding: unset; font-size: unset; ">Open</b>
            <b v-if="!openingClosingTime(list)">Closed</b>
          </div>
        </div>

        <div class="kitchen pt-1">
          {{ categoryWithComma(list.categories) }}
        </div>
        <div class="bottom-wrapper pt-1">
          <div class="mr-3">
            <i class="fas fa-clock pr-1"></i>{{ list.average_delivery_time }}
          </div>
          <div class="mr-3" v-if="list.type == 'Delivery'">
            <i class="fas fa-bicycle pr-1"></i
            >{{ list.addresses.delivery_cost.toFixed(2) }}
          </div>
          <div>
            <i class="fas fa-shopping-bag pr-1"></i>Min
            {{ list.addresses.minimum_order_amount.toFixed(2) }}€
          </div>
        </div>
      </div>
    </div>
<!--    <AboutRestaurant-->
<!--        v-if="showComponent"-->
<!--        :restaurantDetail="aboutRestaurant"-->
<!--        :todayTimeSlot="todayTimeSlot"-->
<!--        :reviewsList="reviewsList"-->
<!--        :loading="isLoading"-->
<!--        @forPagination="forPagination"-->
<!--    />-->
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
// import AboutRestaurant from "./Modals/AboutRestaurant";
// import axios from "axios";
// import {apiUrl} from "@/api/services";

export default {
  name: "restaurantCard",
  data() {
    return {
      // aboutRestaurant: {},
      // showComponent: false,
      todayTimeSlot: {},
      // reviewsList: {},
      restaurantId: {},
      isLoading: false
    }
  },
  props: {
    listing: {
      type: Object,
    },
  },
  components: {
    // AboutRestaurant,
    StarRating,
  },
  methods: {
    // handleModalEventIgnore(list) {
    //   event.ignore = true;
    //   this.showComponent = true;
    //   this.restaurantId = list;
    //   this.isLoading = true;
    //   this.restaurantDetail();
    //
    // },
    categoryWithComma(data) {
      let filterCategory = [];
      data.filter((e) => filterCategory.push(e.restaurant_categories.name));
      return filterCategory.join(", ");
    },
    openingClosingTime(data) {

      let currentDate = new Date();
      const day_name = currentDate.toLocaleDateString("default", {
        weekday: "long",
      });
      const [, current_time] = currentDate.toLocaleDateString("en-us",
          {
            hourCycle: "h23",
            hour: "2-digit",
            minute: "2-digit"
          }).split(", ");

      let timeSlots = data.timeSlots;
      let filtered = timeSlots.find((t) => t.day === day_name);
      let lunch_start_time = filtered.lunch_start_time <= current_time;
      let lunch_end_time = filtered.lunch_end_time >= current_time;
      let dinner_start_time = filtered.dinner_start_time <= current_time;
      let dinner_end_time = filtered.dinner_end_time >= current_time;

      if ((filtered && filtered.status == 1) && ((lunch_start_time && lunch_end_time) || (dinner_start_time && dinner_end_time))) {
        return true
      }
      else {
        return false;
      }
    },
    forPagination: function (page) {
      this.restaurantDetail(page);
    },
    redirectDetail(restaurantId) {
      if(!event.ignore) {
        this.$store.commit("isLoading", true);
        this.$router.push("/restaurant-detail/" + restaurantId);
      }
    },
    /*restaurantDetail: async function (page) {*/
    /*  // this.$store.commit("isLoading", true);*/
    /*  try {*/
    /*    if (typeof page === "undefined") {*/
    /*      page = 1;*/
    /*    }*/
    /*    const data = {*/
    /*      restaurant_id: this.restaurantId,*/
    /*      type: "website",*/
    /*      postcode: this.$store.state.zipCodeSearch,*/
    /*      page: page,*/
    /*    };*/

    /*    const requestOne = axios.post(*/
    /*        apiUrl + "customer/restaurant-detail",*/
    /*        data*/
    /*    );*/

    /*    const requestTwo = axios.post(apiUrl + "restaurant/reviews", data);*/
    /*    const discountList = axios.post(*/
    /*        apiUrl + "customer/discount-list",*/
    /*        data*/
    /*    );*/

    /*      await axios*/
    /*          .all([*/
    /*            requestOne,*/
    /*            requestTwo,*/
    /*            discountList,*/
    /*          ])*/
    /*          .then(*/
    /*              await axios.spread((...responses) => {*/
    /*                const responseOne = responses[0];*/
    /*                const responseTwo = responses[1];*/
    /*                const discountList = responses[2];*/

    /*                if (responseTwo.data.code == 200) {*/
    /*                  this.reviewsList = responseTwo.data.data;*/
    /*                }*/

    /*                if (discountList.data.code == 200) {*/
    /*                  this.$store.commit("setdiscountList", discountList.data.data);*/
    /*                }*/

    /*                if (responseOne.data.code == 200) {*/
    /*                  this.$store.commit(*/
    /*                      "setResturantsDetails",*/
    /*                      responseOne.data.data*/
    /*                  );*/

    /*                  this.aboutRestaurant = responseOne.data.data;*/

    /*                  let currentDate = new Date();*/
    /*                  const day_name = currentDate.toLocaleDateString("default", {*/
    /*                    weekday: "long",*/
    /*                  });*/
    /*                  let timeSlots = responseOne.data.data.timeSlots;*/
    /*                  let filtered = timeSlots.find((t) => t.day === day_name);*/
    /*                  // console.log(filtered.status);*/
    /*                  // this.todayTimeSlot = filtered;*/
    /*                  (filtered.status == 0) ? this.todayTimeSlot = false : this.todayTimeSlot = filtered;*/
    /*                  this.showComponent = true;*/
    /*                  this.showStaffComponent = true;*/
    /*                  this.isLoading = false;*/
    /*                  this.$store.commit("isLoading", false);*/
    /*                } else {*/
    /*                  console.log(*/
    /*                      "response.data.message",*/
    //                       responseOne.data.message
    //                   );
    //                 }
    //               })
    //           );
    //   } catch (err) {
    //     if (!err.response) {
    //       // alert("Please Check You Internet Connection");
    //       this.buttonloader = false;
    //     }
    //   }
    // },

  },
};
</script>

<style scoped>
.content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1.5rem;
}

.restaurantCard {
  border: 1px solid #eee;
  border-radius: 10px;
  cursor: pointer;
}

.topWrapper {
  position: relative;
}

.profileImg {
  border-radius: 50%;
  border: 1px solid #d7d7d7;
  background: #fff;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profileImg img {
  width: 90px;
  height: 90px;
  max-width: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.coverImg img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.bottomWrapper {
  padding: 30px 10px 20px;
}

.kitchen {
  margin-bottom: 0;
}

.restaurant-name {
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #0a3847;
  word-break: break-word;
  margin: 0 0 4px;
  white-space: normal;
}
</style>
