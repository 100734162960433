<template>
  <div>
    <div class="position-relative">
      <section class="top-banner">
        <div class="restaurant-number">
          {{ $t("order_from") }}
          <span>{{ this.$store.state.restaurantLists.pagination.total }}</span>

          {{ $t("restaurants") }}
        </div>
      </section>
    </div>
    <hooper :settings="hooperSettings">
      <slide @click="selected = index">
        <div
          class="scrollactive-item"
          @click="selectCategory('')"
          :class="{ active: selected == 0 }"
        >
          All
        </div>
      </slide>
      <slide v-for="(item, index) in categories" :key="index">
        <div
          @click="selectCategory(item)"
          class="scrollactive-item"
          :class="{ active: selected == item.id }"
        >
          {{ item.name }}
        </div>
      </slide>
      <hooper-navigation slot="hooper-addons"></hooper-navigation>
    </hooper>
    <div class="container-fluid pt-4">
      <div class="search-section">
        <div class="d-flex">
          <div class="search-restaurant">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fas fa-search"></i>
              </b-input-group-prepend>
              <b-form-input
                :placeholder="$t('search_for_restaurant')"
                v-model="searchBarText"
                :value="this.$store.state.searchText"
                @keyup="searchResturantByBar"
              >
              </b-form-input>
            </b-input-group>
          </div>
          <div class="d-flex align-items-center">
            <b-form-select
              class=""
              v-model="filterSelect"
              v-on:change="ratingSort"
            >
              <b-form-select-option value="">{{
                $t("select_sort_filter")
              }}</b-form-select-option>
              <b-form-select-option value="top_rated">{{
                $t("rating")
              }}</b-form-select-option>
            </b-form-select>
          </div>
        </div>
      </div>
    </div>

    <RestaurantListing
      @ratingFilter="ratingFilter"
      @allRestaurants="allRestaurants"
      @typeFiler="typeFiler"
      :isLoader="isLoader"
    />
  </div>
</template>

<script>
import RestaurantListing from "../components/RestaurantListing";
import axios from "axios";
import { apiUrl } from "../api/services";

import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "Listing",
  data() {
    return {
      selected: 0,
      all: false,
      filterSelect: "",
      searchBarText: "",
      isLoader: false,
      categories: this.$store.state.restaurantCategories,
      category_id: null,
      rating: null,
      hooperSettings: {
        wheelControl: false,
        itemsToSlide: 1,
        itemsToShow: 16,
        trimWhiteSpace: true,
      },
    };
  },

  mounted() {
    this.$store.commit("setType", 1);
    this.$store.state.zipCodeSearch = this.$route.params?.all == true ? "" : this.$store.state.zipCodeSearch;
    this.searchResturantByFilter();
  },

  computed: {
    setsearchBarText() {
      return this.$store.state.searchText;
    },
    setCategories(){
      return this.$store.state.restaurantCategories;
    }
  },

  watch: {
    setsearchBarText() {
      this.searchBarText = this.$store.state.searchText;
      console.log("Imran Here");
    },
    setCategories(){
      this.categories = this.$store.state.restaurantCategories;
    }
  },

  methods: {
    
    ratingFilter: function (rating) {
      this.$store.commit("setsearchText", "");
      this.rating = rating;
      this.searchResturantByFilter();
    },

    allRestaurants: function () {
      this.$store.commit("setsearchText", "");
      this.$store.commit("setZipCodeSearch", "");
      this.$store.commit("setType", -1);
      this.filterSelect='All'
      this.searchResturantByFilter();
    },

    typeFiler: function (type) {
      this.$store.commit("setsearchText", "");
      this.$store.commit("setType", type);
      this.searchResturantByFilter();
    },

    selectCategory: function (object) {
      this.$store.commit("setsearchText", "");
      this.category_id = object.id;
      this.selected = object.id ? object.id : 0;
      this.searchResturantByFilter();
    },

    ratingSort: function () {
      this.$store.commit("setsearchText", "");
      this.searchResturantByFilter();
    },
    searchResturantByFilter: async function () {
      this.isLoader = true;
      try {
        const data = {
          sort_by: this.filterSelect ? this.filterSelect : "",
          per_page: "10",
          page: "1",
          postcode: this.$store.state.zipCodeSearch,
          category_id: this.category_id ? this.category_id : "",
          rating: this.rating ? this.rating : "",
          type: this.$store.getters.restaurantType,
          search_text: this.$store.state.searchText,
        };

        console.log("===>", this.$store.getters.restaurantType);

        // if (
        //   this.$store.state.zipCodeSearch.length < 5 &&
        //   this.$store.state.zipCodeSearch.length > 6
        // ) {
        //   console.log("zipcode length not correct");
        //   alert("Please Enter Correct Zipcode Length");
        //   return false;
        // }
        // if (this.$store.state.zipCodeSearch.length > 0) {
          await axios
            .post(apiUrl + "customer/zipbase-restaurant", data)
            .then((response) => {
              if (response.data.code == 200) {
                // if (response.data.data.data.length > 0) {
                this.$store.commit("setSearchResturants", response.data.data);
                this.isLoader = false;
              }
              //   } else {
              //     console.log("response.data.message", response.data.message);
              //   }
            });

            await axios
            .post(apiUrl + "customer/zip-base-category", data)
            .then((response) => {
              if (response.data.code == 200) {
                // if (response.data.data.data.length > 0) {
                  console.log("CATEGORIES", response.data);
                  this.categories = response.data.data;
                this.isLoader = false;
              }
              //   } else {
              //     console.log("response.data.message", response.data.message);
              //   }
            });
        // } else {
        //   if (this.filterSelect.length == 0 || this.filterSelect.length == 0) {
        //     console.log("zipcode length not correct");
        //   }
        // }
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
          this.buttonloader = false;
        }
      }
    },
    searchResturantByBar: async function () {
      this.$store.commit("setsearchText", this.searchBarText);
      const data = {
        sort_by: this.filterSelect ? this.filterSelect : "",
        per_page: "10",
        page: "1",
        postcode: this.$store.state.zipCodeSearch,
        category_id: this.category_id ? this.category_id : "",
        rating: this.rating ? this.rating : "",
        type: this.$store.getters.restaurantType,
        search_text: this.$store.state.searchText,
      };

      await axios
        .post(apiUrl + "customer/zipbase-restaurant", data)
        .then((response) => {
          this.$store.commit("setSearchResturants", response.data.data);          
          // Assign the dummy data for search
          this.restaurantDummyLists = response.data.data;
        });
    },
  },
  components: {
    RestaurantListing,
    Hooper,
    Slide,
    HooperNavigation,
  },
};
</script>
