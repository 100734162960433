<template>
  <div class="mbl-order">
    <b-button v-b-modal.mbl-order class="mbl-order-btn">
      <i class="fas fa-shopping-bag"></i> {{ $t("basket") }} ({{
        (this.$store.getters.total_amount - this.voucher_discount).toFixed(2)
      }}
      €)
    </b-button>
    <b-modal id="mbl-order" size="xl" centered scrollable title="Basket">
      <div class="product-row-wrapper">
        <div
          class="product-row"
          v-for="(list, index) in this.$store.getters.AllorderList"
          :key="index"
        >
          <div class="product-quantity">{{ list.quantity }}x</div>
          <div class="product-name">{{ list.menu_item_name }}</div>
          <div class="product-btn-wrapper">
            <button class="product-buttons" @click="decrement(index)">
              <i class="fas fa-minus"></i>
            </button>
            <button class="product-buttons">
              <i class="fas fa-plus" @click="increment(index)"></i>
            </button>
          </div>
          <div class="product-price">{{ list.total_price.toFixed(2) }} €</div>
          <div class="trash-icon">
            <i class="far fa-trash-alt" @click="spliceOrder(index)"></i>
          </div>
        </div>

        <div>
          <div
            class="text-center pt-3"
            v-if="this.$store.getters.AllorderList.length == 0"
          >
            <i
              class="fas fa-shopping-bag"
              style="font-size: 32px; color: #d7d7d7"
            ></i>
          </div>
          <div
            class="basket-text py-3"
            v-if="this.$store.getters.AllorderList.length == 0"
          >
            Add some tasty food from the menu and place your order.
          </div>
        </div>
        <div class="cart-text">
          <div class="d-flex justify-content-between grey pb-2">
            <span>{{ $t("sub_total") }}</span>
            <span>{{ this.$store.getters.sub_total }} €</span>
          </div>
          <div
            v-if="this.$store.getters.voucher"
            class="d-flex justify-content-between grey pb-2"
          >
            <span
              >Voucher ID {{ this.$store.getters.voucher.unique_code }}</span
            >
            <span>{{ this.voucher_discount.toFixed(2) }} €</span>
          </div>

          <div class="d-flex justify-content-between grey pb-2">
            <span>{{ $t("delivery_charges") }}</span>
            <span
              >{{
                this.$store.getters.AllorderList.length == 0
                  ? "0.00"
                  : this.$store.getters.delivery_address.delivery_cost.toFixed(
                      2
                    )
              }}
              €</span
            >
          </div>
          <div class="d-flex justify-content-between text-bold">
            <span>{{ $t("total") }}</span>
            <span
              >{{
                this.$store.getters.total_amount - this.voucher_discount
              }}
              €</span
            >
          </div>
        </div>

        <router-link to="/checkout">
          <button class="order-btn">{{ $t("check_out") }}</button>
        </router-link>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Listing",
  data() {
    return {
      orderList: [],
      voucher_discount: 0,
    };
  },

  mounted() {},

  methods: {
    subTotal: function () {
      this.sub_total = this.$store.getters.AllorderList.reduce(
        (acc, item) => acc + item.total_price,
        0
      );
      this.$store.commit("setSubTotal", this.sub_total);
      let totlaAmount = this.sub_total;
      if (this.$store.getters.restaurantDetails.type == "Delivery") {
        let restaurant_detail = this.$store.getters.restaurantDetails;
        let deliveryCost = restaurant_detail.addresses.delivery_cost;
        this.$store.dispatch("setDeliveryAddress", "");
        this.$store.dispatch("setDeliveryAddress", restaurant_detail.addresses);
        totlaAmount = this.sub_total + deliveryCost;
      }
      this.$store.commit("setTotalAmount", totlaAmount);
    },
    increment(index) {
      this.orderList = this.$store.getters.AllorderList;
      this.orderList[index].quantity += 1;
      this.orderList[index].total_price =
        this.orderList[index].price * this.orderList[index].quantity;
      this.$store.commit("newsetAddToCart", this.orderList);
      this.subTotal();
    },

    decrement(index) {
      this.orderList = this.$store.getters.AllorderList;
      if (this.orderList[index].quantity == 1) {
        this.orderList[index].quantity = 1;
        this.$store.commit("newsetAddToCart", this.orderList);
        this.subTotal();
        return false;
      }
      this.orderList[index].quantity -= 1;
      this.orderList[index].total_price -= this.orderList[index].price;
      this.$store.commit("newsetAddToCart", this.orderList);
      this.subTotal();
    },

    spliceOrder: function (index) {
      this.orderList = this.$store.getters.AllorderList;
      if (index !== -1) {
        this.orderList.splice(index, 1);
      }

      let menuItems = this.$store.getters.AllorderList.filter(
        (item) => item.menu_id
      );

      this.$store.dispatch("setMenuItems", menuItems);

      let offers = this.$store.getters.AllorderList.filter((item) => item.id);
      this.$store.dispatch("setOffer", offers);

      this.$store.commit("newsetAddToCart", this.orderList);
      this.subTotal();
    },
  },
  components: {},
  watch: {
    setVoucher() {
      if (this.$store.getters.voucher) {
        this.voucher_discount = this.$store.getters.voucher.voucher_discount;
      }
    },
  },
  computed: {
    setVoucher() {
      return this.$store.getters.voucher;
    },
  },
};
</script>
