<template>
  <div>
    <header class="headerImg">
      <div class="banner">
        <div style="display: flex; justify-content: center;column-gap: 0px; font-size: 20px!important;">
          <button class="show-btn button-align" style="background-color: #78acf1!important;">{{ $t("show") }}</button>
          <router-link :to="{ name: 'Listing', params: { all: true } }"
          ><button class="show-btn button-align" style="margin-left: auto;margin-right: auto;display: block;" >{{ $t("bookReservation") }}</button>
          </router-link>
        </div>
        <h1 class="mb-0 pt-4">{{ $t("time") }}</h1>

        <h3 class="mb-0 pt-2">{{ $t("find_restaurant") }}</h3>
        <div class="text-center search-input mt-2">
          <b-input-group>
            <b-input-group-prepend is-text>
              <i class="fas fa-map-marker-alt"></i>
            </b-input-group-prepend>
            <b-form-input
              v-on:keyup.enter="saerchResturant"
              type="search"
              :placeholder="$t('search_plach_holder')"
              v-model="zipcode"
            ></b-form-input>
            <button class="show-btn" v-on:click="saerchResturant">
              {{ $t("show") }}
              <i
                style="color: rgb(123 217 182)"
                class="fa fa-spinner fa-spin"
                v-if="this.$store.state.isLoading"
              ></i>
            </button>
          </b-input-group>
        </div>
        <div
          v-if="alert"
          role="alert"
          aria-live="polite"
          aria-atomic="true"
          class="alert alert-danger"
          style="width: 50%; margin: 10px auto 0"
        >
          {{ alertText }}
        </div>
        <div class="text-center pt-2">
          <router-link to="/" class="logo">
            <img :src="'/images/logo.png'" alt="" />
          </router-link>
        </div>
      </div>
    </header>
    <section class="banner-bottom">
      <div class="container">
        <div class="row pb-3">
          <div class="col-12">
            <div class="title pb-2">{{ $t("how") }}</div>

            <div class="subtitle">{{ $t("easy_us") }}</div>
          </div>
        </div>
        <div class="step-wizard">
          <div class="step-wrapper">
            <span class="step-icon">
              <i class="fas fa-map-marked-alt"></i>
            </span>
            <span class="step-number"> 1. </span>
            <span class="step-line"> {{ $t("your_location") }} </span>
            <p>{{ $t("location_description") }}</p>
          </div>
          <span class="step-separator">
            <i class="fas fa-chevron-right"></i>
          </span>
          <div class="step-wrapper">
            <span class="step-icon">
              <i class="fas fa-hamburger"></i>
            </span>
            <span class="step-number"> 2. </span>
            <span class="step-line">{{ $t("your_restaurant") }} </span>
            <p>{{ $t("choose_description") }}</p>
          </div>
          <span class="step-separator">
            <i class="fas fa-chevron-right"></i>
          </span>
          <div class="step-wrapper">
            <span class="step-icon">
              <i class="fas fa-motorcycle"></i>
            </span>
            <span class="step-number"> 3. </span>
            <span class="step-line"> {{ $t("pay_and_get") }} </span>
            <p>
              {{ $t("pay_description") }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="app-section">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="headline">
              <span>Download the app</span>
            </div>
            <div class="headline">
              <span>Click, sit back and enjoy.</span>
            </div>
            <div class="d-flex pt-2">
              <img :src="'./images/android.png'" alt="" class="mr-2" />
              <img :src="'./images/ios.png'" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-grey banner-bottom">
      <div class="container">
        <div class="row pb-5 mb-5">
          <div class="col-12">
            <div class="title pb-2">Herr lecker</div>
            <div class="subtitle">{{ $t("why") }}</div>
          </div>
        </div>
        <div class="row listbox-wrapper justify-content-center">
          <div class="col-md-5">
            <div class="list-box">
              <span class="listbox-icon">
                <i class="fas fa-shopping-bag"></i>
              </span>
              <div class="listbox-title">{{ $t("your_bonus") }}</div>
              <div class="d-flex">
                <i class="fas fa-check"></i>
                <p class="pl-2">
                  {{ $t("bonus_section_1") }}
                </p>
              </div>
              <div class="d-flex">
                <i class="fas fa-check"></i>
                <p class="pl-2">
                  {{ $t("bonus_section_2") }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="list-box">
              <span class="listbox-icon">
                <i class="fas fa-crown"></i>
              </span>
              <div class="listbox-title">{{ $t("your_gurantee") }}</div>

              <div class="d-flex">
                <i class="fas fa-check"></i>
                <p class="pl-2">
                  {{ $t("guarante_1") }}
                </p>
              </div>
              <div class="d-flex">
                <i class="fas fa-check"></i>
                <p class="pl-2">
                  {{ $t("guarante_2") }}
                </p>
              </div>
              <div class="d-flex">
                <i class="fas fa-check"></i>
                <p class="pl-2">
                  {{ $t("guarante_3") }}
                </p>
              </div>
              <div class="d-flex">
                <i class="fas fa-check"></i>
                <p class="pl-2">
                  {{ $t("guarante_4") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="blogs banner-bottom">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="left-text pb-3">
              {{ $t("what_more") }}<br />Check out exciting news about food
              and<br class="hide-br" />
              lifestyle on our blog.
            </div>
            <a href="#">{{ $t("visit_block") }}</a>
          </div>
          <div class="col-md-6 text-center">
            <img
              :src="'/images/blog.png'"
              class="w-100"
              style="max-width: 400px"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
    <v-scroll-to-top></v-scroll-to-top>
  </div>
</template>

<script>
import axios from "axios";
import { apiUrl } from "../api/services";

import { emptyRestaurantData } from "../global/helper";

export default {
  data() {
    return {
      zipcode: "",
      alertText: "",
      alert: false,
    };
  },
  mounted() {
    emptyRestaurantData();
  },

  methods: {
    saerchResturant: async function () {
      this.$store.commit("isLoading", true);
      this.$store.commit("setType", 1);
      try {
        const data = {
          sort_by: "",
          per_page: "10",
          page: "1",
          postcode: this.zipcode,
          category_id: "",
          type: this.$store.getters.restaurantType,
        };
        if (this.zipcode.length > 4) {
          const requestOne = axios.post(
            apiUrl + "customer/zipbase-restaurant",
            data
          );
          const requestTwo = axios.post(
            apiUrl + "customer/zip-base-category",
            data
          );

          await axios.all([requestOne, requestTwo]).then(
            await axios.spread((...responses) => {
              const responseOne = responses[0];
              const responseTwo = responses[1];

              if (responseOne.data.code == 200) {
                if (responseOne.data.data.data.length > 0) {
                  this.$store.commit("setZipCodeSearch", this.zipcode);
                  this.$store.commit("setZipCode", true);
                  this.$store.commit(
                    "setSearchResturants",
                    responseOne.data.data
                  );
                  this.$store.commit(
                    "setDummySearchResturants",
                    responseOne.data.data
                  );
                  this.$store.commit(
                    "setResturantsCategories",
                    responseTwo.data.data
                  );
                  console.log("responseTwo", responseTwo.data.data);
                  this.$store.commit("isLoading", false);
                  this.$router.push({ path: "/delivery" });
                } else {
                  this.alert = true;
                  this.alertText = "No Record Found";
                }
              } else {
                console.log("response.data.message", responseOne.data.message);
              }
            })
          );
        } else {
          this.alert = true;
          this.alertText = "Please enter valid ZipCode";
        }
      } catch (err) {
        if (!err.response) {
          console.log(err);
          alert("Please Check You Internet Connection");
          this.buttonloader = false;
        }
      }
    },
  },
  watch: {
    zipcode(val) {
      if (val.length < 5) {
        this.alert = false;
        this.alertText = "";
      }
    },
  },
};
</script>
