import Vue from "vue";
// import axios from "axios";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loginUserData: localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : {},
    isAuthenticated: localStorage.getItem("isAuthenticated")
      ? JSON.parse(localStorage.getItem("isAuthenticated"))
      : false,
    stripePublicKey:
      "pk_test_51JIsjoJDJJJr4FA7cIvpqE0xI607R6eqiHlgHtMKj85WwJ6oP6oOiX1VxTfWXIZd0VBvTv2AFMghssNrM8WG1KiK00HfsuMG1B",
    // baseUrlCustomer:
    //   "https://gutenappetite-backend.crunchyapps.com/api/customer/",
    // baseUrl: "https://gutenappetite-backend.crunchyapps.com/api/",
    // apiBaseUrl: "https://gutenappetite-backend.crunchyapps.com/api/",

    baseUrlCustomer: "https://testing-backend.herrlecker.de/api/customer/",
    baseUrl: "https://testing-backend.herrlecker.de/api/",
    apiBaseUrl: "https://testing-backend.herrlecker.de/api/",

    // baseUrlCustomer: "https://guten-backend.tajtrudering.de/api/customer/",
    // baseUrl: "https://guten-backend.tajtrudering.de/api/",
    // apiBaseUrl: "https://guten-backend.tajtrudering.de/api/",
    restaurantLists: [],
    restaurantDummyLists: [],
    restaurantCategories: [],
    restaurantDetails: {},
    orderList: [],
    offersList: [],
    restaurantId: "",
    orderRestaurant: {},
    voucher: {},
    menuItems: [],
    discountList: [],
    zipCodeSearch: "",
    isLoading: false,
    zipCode: false,
    restaurantType: 1,
    searchText: "",
    sub_total: 0,
    total_amount: 0,
    delivery_address: {},
  },
  plugins: [createPersistedState()],

  mutations: {
    updateLoginUser(state, data) {
      state.loginUserData = data;
    },

    isLogin(state, data) {
      state.isAuthenticated = data;
    },

    isLoading(state, data) {
      state.isLoading = data;
    },

    setZipCode(state, data) {
      state.zipCode = data;
    },
    setRestaurantId(state, data) {
      state.restaurantId = data;
    },

    setorderRestaurant(state, data) {
      state.orderRestaurant = data;
    },

    setDeliveryAddress(state, data) {
      state.delivery_address = data;
    },

    setsearchText(state, data) {
      state.searchText = data;
    },

    setType(state, data) {
      state.restaurantType = data;
    },

    setSubTotal(state, data) {
      state.sub_total = data;
    },
    setTotalAmount(state, data) {
      state.total_amount = data;
    },

    setSearchResturants(state, data) {
      state.restaurantLists = data;
    },

    setDummySearchResturants(state, data) {
      state.restaurantDummyLists = data;
    },

    setResturantsCategories(state, data) {
      state.restaurantCategories = data;
    },

    setResturantsDetails(state, data) {
      state.restaurantDetails = data;
    },
    setVoucher(state, data) {
      state.voucher = data;
    },

    setdiscountList(state, data) {
      state.discountList = data;
    },

    newsetAddToCart(state, data) {
      state.orderList = data;
    },

    setOffer(state, data) {
      state.offersList = data;
    },
    setMenuItems(state, data) {
      state.menuItems = data;
    },

    setZipCodeSearch(state, data) {
      state.zipCodeSearch = data;
    },
  },
  actions: {
    setOffer({ commit }, data) {
      commit("setOffer", data);
    },
    setRestaurantId({ commit }, data) {
      commit("setRestaurantId", data);
    },
    setorderRestaurant({ commit }, data) {
      commit("setorderRestaurant", data);
    },
    setvoucher({ commit }, data) {
      commit("setvoucher", data);
    },
    setMenuItems({ commit }, data) {
      commit("setMenuItems", data);
    },

    setDeliveryAddress({ commit }, data) {
      commit("setDeliveryAddress", data);
    },
  },

  getters: {
    allSearchResturants: (state) => state.restaurantLists,
    SearchResturantsDetails: (state) => state.restaurantLists,
    AllorderList: (state) => state.orderList,
    sub_total: (state) => state.sub_total,
    total_amount: (state) => state.total_amount,
    discountList: (state) => state.discountList,
    restaurantDetails: (state) => state.restaurantDetails,
    restaurantType: (state) => state.restaurantType,
    offersList: (state) => state.offersList,
    restaurantId: (state) => state.restaurantId,
    orderRestaurant: (state) => state.orderRestaurant,
    voucher: (state) => state.voucher,
    menuItems: (state) => state.menuItems,
    delivery_address: (state) => state.delivery_address,
  },

  modules: [
    // criterias,
  ],
});

export default store;
