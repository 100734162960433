<template>
  <div>
    <b-modal
      id="menu-list"
      ref="menu-list"
      :no-close-on-backdrop="true"
      centered
      scrollable
      size="sm"
    >
      <template #modal-header>
        <h5 class="modal-title">Discounted Items</h5>
        <button @click="hideModal()" type="button" class="close">×</button>
      </template>
      <div
        class="restaurant-listing mr-0"
        v-for="(list, index) in menuList.discountItems"
        :key="index"
      >
        <div class="logo-wrapper">
          <img :src="list.coverImage" alt="" />
          <span>€ {{ list.actual_price }}</span>
        </div>

        <div class="details-wrapper">
          <div class="bottom-wrapper">
            <div style="font-weight: 700; font-size: 16px; color: black">
              {{list.menuItemId}}{{ list.menuItemId ? ". " : ""}}{{ list.menu_item_name }}
            </div>
          </div>
          <div class="meal-description mb-1" style="font-weight: 400">
            <span>{{ list.short_description }}</span>
          </div>
          <div>
            <div v-if="list.allergens.length > 0">
              <div style="font-weight: 700;margin-right">Allergies:</div>
              <div class="meal-description" style="font-weight: 400">
                <span> {{ alergen(list) }} </span>
              </div>
            </div>
          </div>

          <div
            class="bottom-wrapper justify-content-between align-items-center"
          ></div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    MenuItems: Object,
  },
  data() {
    return {
      menuList: this.MenuItems,
    };
  },

  mounted() {
    console.log(this.menuList.discountItems);
    this.$refs["menu-list"].show();
  },
  methods: {
    hideModal() {
      this.$emit("closeModal", false);
    },
    alergen(data) {
      return data.allergens.map(({ name }) => name).join(", ");
    },
  },
};
</script>
<style scoped>
.logo-wrapper {
  flex-direction: column;
}

.title {
  font-size: 20px;
  color: #666;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 10px;
}

.desc {
  font-size: 13px;
  font-weight: 400;
  color: #666;
  line-height: 20px;
}

.label {
  font-size: 15px;
  color: #666;
  padding-top: 20px;
  padding-bottom: 5px;
}

.mainLabel {
  font-size: 11px;
  color: #666;
}

textarea {
  width: 100%;
  resize: none;
  border-radius: 5px;
  border-color: #dedede;
  padding: 5px;
}
</style>
