<template>
  <div>
    <section class="container py-5">
      <div class="row">
        <div class="col-12">
          <div class="heading">Reset Password</div>
          <div
            v-if="alert"
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
          >
            {{ alertText }}
          </div>
        </div>
        <div class="col-12">
          <b-form v-on:submit.prevent="onSubmit" ref="form_id">
            <div class="row">
              <b-form-group
                :label="$t('form_password') + ' *'"
                class="col-12 col-md-6"
              >
                <b-form-input
                  v-model="password"
                  type="password"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('form_c_password') + ' *'"
                class="col-md-6 col-12"
              >
                <b-form-input
                  v-model="confirm_password"
                  type="password"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </div>
            <button type="submit" class="registerBtn">
              <i
                style="color: rgb(123 217 182)"
                class="fa fa-spinner fa-spin"
                v-if="buttonloader"
              ></i
              >Reset
            </button>
          </b-form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { resetPassword } from "../api/services";

export default {
  data() {
    return {
      buttonloader: false,
      alert: false,
      alertText: "",
      password: "",
      confirm_password: "",
      changeClasss: false,
    };
  },

  components: {},

  mounted() {},

  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      if (this.password != this.confirm_password) {
        this.alert = true;
        this.alertText = "Password can not match";
        return false;
      }
      let apiData = JSON.stringify({
        password: this.password,
        confirm_password: this.confirm_password,
        code: this.$route.params.code,
      });
      console.log("df");

      resetPassword(apiData).then((responce) => {
        console.log(responce);
        if (responce.code == 200) {
          this.alertText = responce.message;
          this.alert = true;
          this.buttonloader = false;
          this.changeClasss = true;
        } else {
          this.alertText = responce.message;
          this.alert = true;
          this.buttonloader = false;
          this.changeClasss = false;
        }
      });
    },
  },
};
</script>
