<template>
  <div>
    <section class="container py-5">
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
          <ImageLoader v-if="!showTable" />

          <table v-if="showTable" class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th>Identifier</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody class="allergies">
              <tr v-for="(list, index) in alergies" :key="index">
                <td>
                  {{ list.identifier }}
                </td>
                <td>
                  {{ list.name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { alergies } from "../api/services";
import ImageLoader from "./ImageLoader";
export default {
  name: "SignupRestaurant",
  data() {
    return {
      alergies: [],
      showTable: false,
    };
  },
  components: {
    ImageLoader,
  },
  mounted() {
    this.alergiesList();
  },
  methods: {
    alergiesList() {
      let apiData = {
        type: "menu",
      };
      alergies(apiData).then((responce) => {
        if (responce.code == 200) {
          this.alergies = responce.data;
          this.showTable = true;
        }
      });
    },
  },
};
</script>

<style>
tbody.allergies {
  height: 80em;
  overflow: scroll;
}
</style>
