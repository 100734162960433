<template>
  <div>
    <div v-if="listing.data.length > 0" class="title">
      {{ $t("reserve_booking") }}
    </div>
    <div v-if="listing.data.length == 0" class="title text-center">
      {{ $t("record_not_found") }}
    </div>

    <div
      class="restaurant-listing"
      v-for="(list, index) in listing.data"
      :key="index"
    >
      <div class="logo-wrapper">
        <img :src="list.restaurant.coverImage" alt="" />
        <span>{{ list.unique_code }}</span>
      </div>

      <div class="details-wrapper">
        <div
          class="restaurant-name d-flex justify-content-between align-items-center"
        >
          <a class="col-md-3" href="#">{{ list.restaurant.name }}</a>
          <div :class="list.order_status == 'rejected' ? 'text-danger' : ''">
            {{ capitalizeFirstLetter(list.progress_status) }}
          </div>
        </div>

        <div class="bottom-wrapper">
          <span class="col-md-2">{{ $t("form_name") }}</span>
          <span>{{ list.name }}</span>
        </div>
        <div class="bottom-wrapper">
          <span class="col-md-2">{{ $t("booking_email") }}</span>
          <span>{{ list.email }}</span>
        </div>
        <div class="bottom-wrapper">
          <span class="col-md-2">{{ $t("booking_person") }}</span>
          <span>{{ list.person }}</span>
        </div>
        <div class="bottom-wrapper" v-if="list.message.length > 0">
          <span class="col-md-2">{{ $t("booking_message") }}</span>
          <span>{{ list.message }}</span>
        </div>
        <div>
          <span class="float-right">{{
            moment(list.booking_date_time).format("DD-MM-YYYY HH:mm")
          }}</span>
        </div>
      </div>
    </div>
    <template v-if="isShowModel">
      <Rating
        :show="showModal"
        @closeModal="closeModal"
        :order="order"
        @doneResponce="doneResponce"
      />
    </template>
    <pagination
      :data="listing.pagination"
      :limit="4"
      align="center"
      @pagination-change-page="bookingPagination"
    >
    </pagination>
  </div>
</template>

<script>
import Rating from "../components/Modals/Rating";

export default {
  name: "Favourites",
  props: {
    listing: {
      type: Object,
    },
  },
  data() {
    return {
      order: {},
      showModal: false,
      isShowModel: false,
    };
  },
  components: { Rating },
  methods: {
    reserveInfo(key) {
      this.listing = this.order;
      console.log(key);
    },
    bookingPagination(page = 1) {
      const data = {
        per_page: "10",
        page: page,
      };
      this.$emit("bookingPagination", data);
    },
    closeModal(value) {
      this.isShowModel = value;
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  mounted() {},
};
</script>

<style scoped>
.logo-wrapper {
  flex-direction: column;
}

/* .bottom-wrapper span:nth-child(2) {
  padding-left: 0px;
} */

.title {
  font-size: 20px;
  color: #666;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 10px;
}

.desc {
  font-size: 13px;
  font-weight: 400;
  color: #666;
  line-height: 20px;
}

.label {
  font-size: 15px;
  color: #666;
  padding-top: 20px;
  padding-bottom: 5px;
}

.mainLabel {
  font-size: 11px;
  color: #666;
}

textarea {
  width: 100%;
  resize: none;
  border-radius: 5px;
  border-color: #dedede;
  padding: 5px;
}
</style>
