<template>
  <div class="custom-container py-5">
    <div class="row">
      <div class="col-12">
        <h1>
          <b>1- {{ $t("cookie_statement_h1") }}</b>
        </h1>
        <p>
          {{ $t("cookie_statement_desc_1") }} <br /><br /><b
            style="font-size: 13px; font-weight: bold"
            >{{ $t("cookie_statement_desc_2") }}</b
          >
        </p>

        <h1>
          <b>2 - {{ $t("cookie_statement_section_3_h3") }}</b>
        </h1>

        <p>
          {{ $t("cookie_statement_section_3_desc1") }} <br /><br /><span
            style="font-size: 20px; font-weight: bold"
            class="ml-4"
            >2. {{ $t("cookie_statement_section_3_point_1") }}</span
          >
          <br /><br /><span style="font-size: 20px; font-weight: bold">
            a . {{ $t("cookie_statement_section_3_point_1_desc") }}</span
          >
          <br /><br />{{ $t("cookie_statement_section_3_point_2") }}
          <br />
          <br /><br />

          <span v-html="$t('cookie_statement_section_2_2_a_1')"></span>
          <br /><br />
        </p>
        <p style="font-size: 21px; font-weight: 500">
          <b>b . {{ $t("cookie_statement_section_4_heading") }}</b>
        </p>
        <p>
          {{ $t("cookie_statement_section_4_desc") }} <br /><br />
          <span style="font-size: 21px; font-weight: 500"
            ><b> c . {{ $t("cookie_statement_section_4_point_1") }}</b></span
          ><br /><br />

          {{ $t("cookie_statement_section_4_point_1_desc") }}<br /><br />

          <span style="font-size: 21px; font-weight: 500"
            ><b> 4 . {{ $t("cookie_statement_section_4_point_2") }}</b></span
          ><br /><br />

          <span v-html="$t('cookie_statement_section_2_point_2_4_desc')"></span>
          <br /><br />

          <b style="font-size: 21px; font-weight: 500"
            >b .
            <span v-html="$t('cookie_statement_section_2_point_2_4_b_h')"></span
          ></b>
          <br /><br />
          {{ $t("cookie_statement_section_2_point_2_4_b_desc") }}<br /><br />

          <b style="font-size: 21px; font-weight: 500">
            b .
            <span v-html="$t('cookie_statement_section_2_point_2_4_c_h')"></span
          ></b>
          <br /><br />
          {{ $t("cookie_statement_section_2_point_2_4_c_desc") }}

          <br /><br />
          <b style="font-size: 21px; font-weight: 800">
            5 .
            <span v-html="$t('cookie_statement_section_5_h')"></span
          ></b>

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            a .
            <span v-html="$t('cookie_statement_section_5_a_h')"></span
          ></b>
          <br /><br />
          {{ $t("cookie_statement_section_5_a_desc") }}

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            b .
            <span v-html="$t('cookie_statement_section_5_b_h')"></span
          ></b>
          <br /><br />
          {{ $t("cookie_statement_section_5_b_desc") }}

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            c.
            <span v-html="$t('cookie_statement_section_5_c_h')"></span
          ></b>
          <br /><br />
          {{ $t("cookie_statement_section_5_c_desc") }}

          <br /><br />
          <b style="font-size: 21px; font-weight: 800">
            6 .
            <span v-html="$t('cookie_statement_section_6_h')"></span
          ></b>
          <br /><br />

          {{ $t("cookie_statement_section_6_desc") }}

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            a .
            <span v-html="$t('cookie_statement_section_6_a_h')"></span
          ></b>
          <br /><br />
          <span v-html="$t('cookie_statement_section_6_a_desc')"></span>

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            b .
            <span v-html="$t('cookie_statement_section_6_b_h')"></span
          ></b>
          <br /><br />
          {{ $t("cookie_statement_section_6_b_desc") }}

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            c.
            <span v-html="$t('cookie_statement_section_6_c_h')"></span
          ></b>
          <br /><br />
          {{ $t("cookie_statement_section_6_c_desc") }}

          <br /><br />
          <b style="font-size: 21px; font-weight: 800">
            7 .
            <span v-html="$t('cookie_statement_section_7_h')"></span
          ></b>

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            a .
            <span v-html="$t('cookie_statement_section_7_a_h')"></span
          ></b>
          <br /><br />
          {{ $t("cookie_statement_section_7_a_desc") }}

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            b .
            <span v-html="$t('cookie_statement_section_7_b_h')"></span
          ></b>
          <br /><br />
          {{ $t("cookie_statement_section_7_b_desc") }}

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            c.
            <span v-html="$t('cookie_statement_section_7_c_h')"></span
          ></b>
          <br /><br />
          {{ $t("cookie_statement_section_7_c_desc") }}

          <br /><br />
          <b style="font-size: 21px; font-weight: 800">
            8 .
            <span v-html="$t('cookie_statement_section_8_h')"></span
          ></b>
          <br /><br />
          <span v-html="$t('cookie_statement_section_8_desc')"></span>

          <br /><br />
          <b style="font-size: 21px; font-weight: 800">
            9 .
            <span v-html="$t('cookie_statement_section_9_h')"></span
          ></b>

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            a .
            <span v-html="$t('cookie_statement_section_9_a_h')"></span
          ></b>
          <br /><br />
          {{ $t("cookie_statement_section_9_a_desc") }}

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            b .
            <span v-html="$t('cookie_statement_section_9_b_h')"></span
          ></b>
          <br /><br />
          {{ $t("cookie_statement_section_9_b_desc") }}

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            c.
            <span v-html="$t('cookie_statement_section_9_c_h')"></span
          ></b>
          <br /><br />
          {{ $t("cookie_statement_section_9_c_desc") }}

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            d.
            <span v-html="$t('cookie_statement_section_9_d_h')"></span
          ></b>
          <br /><br />
          {{ $t("cookie_statement_section_9_d_desc") }}

          <br /><br />
          <b style="font-size: 21px; font-weight: 800">
            10 .
            <span v-html="$t('cookie_statement_section_10_h')"></span
          ></b>

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            a .
            <span v-html="$t('cookie_statement_section_10_a_h')"></span
          ></b>
          <br /><br />
          {{ $t("cookie_statement_section_10_a_desc") }}

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            b .
            <span v-html="$t('cookie_statement_section_10_b_h')"></span
          ></b>
          <br /><br />
          <span v-html="$t('cookie_statement_section_10_b_desc')"></span>

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            c.
            <span v-html="$t('cookie_statement_section_10_c_h')"></span
          ></b>
          <br /><br />
          {{ $t("cookie_statement_section_10_c_desc") }}

          <br /><br />
          <b style="font-size: 21px; font-weight: 800">
            11 .
            <span v-html="$t('cookie_statement_section_11_h')"></span
          ></b>

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            a .
            <span v-html="$t('cookie_statement_section_11_a_h')"></span
          ></b>
          <br /><br />
          <span v-html="$t('cookie_statement_section_11_a_desc')"></span>

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            b .
            <span v-html="$t('cookie_statement_section_11_b_h')"></span
          ></b>
          <br /><br />
          <span v-html="$t('cookie_statement_section_11_b_desc')"></span>

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            c.
            <span v-html="$t('cookie_statement_section_11_c_h')"></span
          ></b>
          <br /><br />
          <span v-html="$t('cookie_statement_section_11_c_desc')"></span>

          <br /><br />
          <b style="font-size: 21px; font-weight: 800">
            12 .
            <span v-html="$t('cookie_statement_section_12_h')"></span
          ></b>

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            a .
            <span v-html="$t('cookie_statement_section_12_a_h')"></span
          ></b>
          <br /><br />
          <span v-html="$t('cookie_statement_section_12_a_desc')"></span>

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            b .
            <span v-html="$t('cookie_statement_section_12_b_h')"></span
          ></b>
          <br /><br />
          <span v-html="$t('cookie_statement_section_12_b_desc')"></span>

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            c.
            <span v-html="$t('cookie_statement_section_12_c_h')"></span
          ></b>
          <br /><br />
          <span v-html="$t('cookie_statement_section_12_c_desc')"></span>

          <br /><br />
          <b style="font-size: 21px; font-weight: 800">
            13 .
            <span v-html="$t('cookie_statement_section_13_h')"></span
          ></b>

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            a .
            <span v-html="$t('cookie_statement_section_13_a_h')"></span
          ></b>
          <br /><br />
          <span v-html="$t('cookie_statement_section_13_a_desc')"></span>

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            b .
            <span v-html="$t('cookie_statement_section_13_b_h')"></span
          ></b>
          <br /><br />
          <span v-html="$t('cookie_statement_section_13_b_desc')"></span>

          <br /><br />
          <b style="font-size: 21px; font-weight: 700">
            c.
            <span v-html="$t('cookie_statement_section_13_c_h')"></span
          ></b>
          <br /><br />
          <span v-html="$t('cookie_statement_section_13_c_desc')"></span>

          <br /><br />
          <b style="font-size: 21px; font-weight: 800">
            14 .
            <span v-html="$t('cookie_statement_section_14_h')"></span
          ></b>
          <br /><br />
          <span v-html="$t('cookie_statement_section_14_desc')"></span>
          <br><br><br><br>

                           
                           <table class="table table-bordered">
                  <thead>
                  <tr>
                  <th>Name</th>
                  <th>Anbieter</th>
                  <th>Zweck</th>
                  <th>Ablauf</th>
                  <th>Typ</th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr>
                  <td>__cf_bm</td>
                  <td><a href="javascript:;">Hubspot</a></td>
                  <td>Dieser Cookie wird verwendet, um zwischen Menschen und Bots zu unterscheiden. Dies ist vorteilhaft für die webseite, um gültige Berichte über die Nutzung ihrer webseite zu erstellen.</td>
                  <td>1 Tag</td>
                  <td>HTTP Cookie</td>
                  </tr>

                  <tr>
                  <td>CookieConsent</td>
                  <td><a href="javascript:;">Cookiebot</a></td>
                  <td>Speichert den Zustimmungsstatus des Benutzers für Cookies auf der aktuellen Domäne.</td>
                  <td>1 Jahr</td>
                  <td>HTTP Cookie</td>
                  </tr>


                  <tr>
                  <td>JSESSIONID</td>
                  <td><a href="javascript:;">New Relic</a></td>
                  <td>Behält die Zustände des Benutzers bei allen Seitenanfragen bei.</td>
                  <td>1 Jahr</td>
                  <td>HTTP Cookie</td>
                  </tr>
                  </tbody>

                  </table>




                  <br /><br />

         
          <b style="font-size: 21px; font-weight: 800">
          
            <span v-html="$t('table_2_heading')"></span
          ></b>
          <br /><br />
          <span v-html="$t('table_2_desc')"></span><br><br><br><br>


    
                  <table class="table table-bordered">
                  <thead>
                  <tr>
                  <th>Name</th>
                  <th>Anbieter</th>
                  <th>Zweck</th>
                  <th>Ablauf</th>
                  <th>Typ</th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr>
                  <td>messagesUtk</td>
                  <td><a href="javascript:;">Hubspot</a></td>
                  <td>Speichert eine eindeutige ID-Zeichenfolge für jede Chat-Box-Sitzung. Dies ermöglicht der Webseite-Unterstützung, frühere Probleme zu sehen und sich wieder mit dem vorherigen Unterstützer zu verbinden.</td>
                  <td>1 Jahr</td>
                  <td>HTTP Cookie</td>
                  </tr>

                  </tbody>

                  </table>


                  <br /><br />

         
<b style="font-size: 21px; font-weight: 800">
 
  <span v-html="$t('table_3_heading')"></span
></b>
<br /><br />
<span v-html="$t('table_3_desc')"></span><br><br><br><br>



                  <table class="table table-bordered">
                  <thead>
                  <tr>
                  <th>Name</th>
                  <th>Anbieter</th>
                  <th>Zweck</th>
                  <th>Ablauf</th>
                  <th>Typ</th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr>
                  <td>__ptq.gif</td>
                  <td><a href="javascript:;">Hubspot</a></td>
                  <td>Sends data to the marketing platform Hubspot about the visitor’s device and behaviour. Tracks the visitor across devices and marketing channels.</td>
                  <td>Session</td>
                  <td>Pixel Tracker</td>
                  </tr>


                  <tr>
                  <td>__tld__</td>
                  <td><a href="javascript:;">Segment</a></td>
                  <td>Wird verwendet, um Besucher auf mehreren Webseiten zu verfolgen, um relevante Werbung basierend auf den Präferenzen des Besuchers zu präsentieren.</td>
                  <td>Session</td>
                  <td>HTTP Cookie</td>
                  </tr>

                  <tr>
                  <td>_fbp</td>
                  <td><a href="javascript:;">Facebook</a></td>
                  <td>Wird von Facebook genutzt, um eine Reihe von Werbeprodukten anzuzeigen, zum Beispiel Echtzeitgebote dritter Werbetreibender.</td>
                  <td>3 Monate</td>
                  <td>HTTP Cookie</td>
                  </tr>

                  <tr>
                  <td>ads/ga-audiences</td>
                  <td><a href="javascript:;">Google</a></td>
                  <td>Used by Google AdWords to re-engage visitors that are likely to convert to customers based on the visitor’s online behaviour across websites.</td>
                  <td>Session</td>
                  <td>Pixel Tracker</td>
                  </tr>


                  <tr>
                  <td>ajs_user_id</td>
                  <td><a href="javascript:;">Segment</a></td>
                  <td>Sammelt Daten über Präferenzen und Verhalten der Besucher auf der Webseite – Diese Informationen werden verwendet, um Inhalte und Werbung für den jeweiligen Besucher relevanter zu machen.</td>
                  <td>Persistent</td>
                  <td>HTML Local Storage</td>
                  </tr>


                  <tr>
                  <td>tr</td>
                  <td><a href="javascript:;">Facebook</a></td>
                  <td>Wird von Facebook genutzt, um eine Reihe von Werbeprodukten anzuzeigen, zum Beispiel Echtzeitgebote dritter Werbetreibender.</td>
                  <td>Session</td>
                  <td>Pixel Tracker</td>
                  </tr>

                  </tbody>

                  </table>
            

                  <br /><br />

         
<b style="font-size: 21px; font-weight: 800">
 
  <span v-html="$t('table_4_heading')"></span
></b>
<br /><br />
<span v-html="$t('table_4_desc')"></span><br><br><br><br>
               

               


                  <table class="table table-bordered">
                  <thead>
                  <tr>
                  <th>Name</th>
                  <th>Anbieter</th>
                  <th>Zweck</th>
                  <th>Ablauf</th>
                  <th>Typ</th>
                  </tr>
                  </thead>

                  <tbody>

                  <tr>
                  <td>_ga</td>
                  <td><a href="javascript:;">Google</a></td>
                  <td>Registriert eine eindeutige ID, die verwendet wird, um statistische Daten dazu, wie der Besucher die Website nutzt, zu generieren.</td>
                  <td>2 Jahr</td>
                  <td>HTTP Cookie</td>
                  </tr>


                  <tr>
                  <td>_gat</td>
                  <td><a href="javascript:;">Google</a></td>
                  <td>Wird von Google Analytics verwendet, um die Anforderungsrate einzuschränken</td>
                  <td>1 Tag</td>
                  <td>HTTP Cookie</td>
                  </tr>


                  <tr>
                  <td>_gid</td>
                  <td><a href="javascript:;">Google</a></td>
                  <td>Registriert eine eindeutige ID, die verwendet wird, um statistische Daten dazu, wie der Besucher die Website nutzt, zu generieren.</td>
                  <td>1 Tag</td>
                  <td>HTTP Cookie</td>
                  </tr>


                  <tr>
                  <td>ajs_anonymous_id</td>
                  <td><a href="javascript:;">Segment</a></td>
                  <td>Dieses Cookie wird benutzt, um einen spezifischen Besucher zu identifizieren – diese Information wird benutzt, um die Anzahl spezifischer Benutzer auf einer Website zu identifizieren.</td>
                  <td>1 Jahr</td>
                  <td>HTML Local Storage</td>
                  </tr>


                  <tr>
                  <td>amplitude_unsent_#</td>
                  <td><a href="javascript:;">cdn.amplitude.com</a></td>
                  <td>Wird im Zusammenhang mit den Popup-Fragebögen und xxx der Website verwendet – Die daraus gewonnen Daten werden für statistische Marketingzwecke verwendet.</td>
                  <td>Persistent</td>
                  <td>HTML Local Storage</td>
                  </tr>


                  <tr>
                  <td>amplitude_unsent_identify_#</td>
                  <td><a href="javascript:;">cdn.amplitude.com</a></td>
                  <td>Wird im Zusammenhang mit den Popup-Fragebögen und xxx der Website verwendet – Die daraus gewonnen Daten werden für statistische Marketingzwecke verwendet.</td>
                  <td>Persistent</td>
                  <td>HTML Local Storage</td>
                  </tr>



                  </tbody>

                  </table>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieStatement",
};
</script>
