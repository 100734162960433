<template>
  <div>
    <b-modal
      ref="my-account"
      id="my-account"
      size="lg"
      centered
      scrollable
      ok-only
      no-stacking
    >
      <template #modal-header="{ close }">
        <div class="modal-image">
          <i class="fa fa-smile-o"></i>
        </div>
        <div class="flex-column ml-3">
          <div class="my-account">{{ $t("my_account") }}</div>
          <!-- <div v-if="this.$store.state.isAuthenticated">
            <span
              style="cursor: pointer"
              class="personal-data"
              @click="accountUrl()"
              >View Personal Data</span
            >
          </div> -->
        </div>
        <button type="button" aria-label="Close" class="close" @click="close()">
          ×
        </button>
      </template>
      <div class="d-flex justify-content-between pt-4 pb-3">
        <button
          class="login-btn-white"
          v-if="!this.$store.state.isAuthenticated"
          v-b-modal.login
        >
          {{ $t("login") }}
        </button>
        <button
          class="login-btn-green"
          v-if="!this.$store.state.isAuthenticated"
          v-b-modal.create-account
        >
          {{ $t("create_account") }}
        </button>
      </div>
      <div class="pb-3" v-if="this.$store.state.isAuthenticated">
        <div class="account-cards" @click="accountUrl()">
          <i class="fa fa-shopping-basket pr-3"></i> {{ $t("order") }}
        </div>
        <div class="account-cards second" @click="accountUrl()">
          <i class="fa fa-heart pr-3"></i> {{ $t("favourites") }}
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "Favourites",
  components: {},
  methods: {
    accountUrl() {
      if (this.$router.history.current.name !== "MyAccount") {
        this.$router.push({ path: "/my-account" });
      }
      this.$refs["my-account"].hide();
    },
  },
  mounted() {
    console.log(this.$store.state.isAuthenticated);
  },
};
</script>
