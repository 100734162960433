<template>
  <div>
    <b-modal
      id="add-address"
      ref="add-address"
      size="sm"
      centered
      title="Add address"
    >
      <b-form @submit="onSubmit">
        <div class="row">
          <div
            class="mb-6 col-md-6 col-12"
            v-if="this.$store.state.isAuthenticated"
          >
            <b-form-group label="Delivery Address *">
            <GmapAutocomplete
                    id="autocomplete"
                    ref="autocomplete"
                    style="width: 100%; height: 39px"
                    placeholder="Please type address"
                    @place_changed="googlePlaceApi"
                  >
                  </GmapAutocomplete>
              
            </b-form-group>
          </div>
          <b-form-group :label="$t('postcode')+' *'" class="col-md-6 col-6">
            <b-form-input required type="text" disabled v-model="postcode" placeholder="">
            </b-form-input>
          </b-form-group>
          <b-form-group :label="$t('city')+' *'" class="col-md-6 col-6">
            <b-form-input required type="text" disabled v-model="city" placeholder="">
            </b-form-input>
          </b-form-group>
          <b-form-group :label="$t('street_no')+' *'" class="col-md-6 col-6">
            <b-form-input type="text" required v-model="street" placeholder="">
            </b-form-input>
          </b-form-group>
          <b-form-group :label="$t('floor')" class="col-md-6 col-6">
            <b-form-input type="text" v-model="floor" placeholder="">
            </b-form-input>
          </b-form-group>
          <b-form-group :label="$t('h_no') + ' *'" class="col-md-6 col-12">
            <b-form-input
              v-model="house_no"
              type="text"
              placeholder=""
              required
            >
            </b-form-input>
          </b-form-group>

          <div class="col-6 col-md-4 d-flex align-items-center">
            <button class="btn btn-primary mb-3">
              {{ $t("add") }} {{ $t("address_footer") }}
            </button>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { updateProfile } from "../../api/services";
// import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "AddAccount",
  data() {
    return {
      postcode: "",
      house_no: "",
      floor: "",
      street: "",
      street_number:"",
      route:"",
      city: "",
    };
  },
  components: {
    // VueGoogleAutocomplete,
    // ModelListSelect,
  },
  methods: {
     googlePlaceApi: function (addressData) {
      var places = addressData;
      this.lat = places.geometry.location.lat();
      this.lng = places.geometry.location.lng();
      for (var i = 0; i < places.address_components.length; i++) {
        for (var j = 0; j < places.address_components[i].types.length; j++) {
          if (places.address_components[i].types[j] == "postal_code") {
            this.postcode = places.address_components[i].long_name;
          }
          if (places.address_components[i].types[j] == "locality") {
            this.city = places.address_components[i].long_name;
          }
          if (places.address_components[i].types[j] == "street_number") {
            this.street_number = places.address_components[i].long_name;
          }
          if (places.address_components[i].types[j] == "route") {
            this.route = places.address_components[i].long_name;
          }
        }
      }
      this.street = this.route + "" + this.street_number;
    },
    
    onSubmit(evt) {
      evt.preventDefault();
      let addresses = this.$store.state.loginUserData.addresses;

      let data = {
        postcode: this.postcode,
        street: this.street,
        house_no: this.house_no,
        address: this.address,
        city: this.city,
        floor: this.floor,
        state: this.state,
      };
      addresses.push(data);
      let apiData = JSON.stringify({
        addresses: JSON.stringify(addresses),
        username: this.$store.state.loginUserData.username,
        platform: "web",
      });
      updateProfile(apiData).then((responce) => {
        if (responce.code == 200) {
          this.clearFiled();
          console.log(responce);
          this.$refs["add-address"].hide();
          this.$store.commit("updateLoginUser", responce.data);
          localStorage.setItem("currentUser", JSON.stringify(responce.data));
          localStorage.setItem("token", JSON.stringify(responce.data.token));
          this.favShow = true;
          this.restaurantList = responce.data;
        } else {
          console.log(responce);
        }
      });
    },

    clearFiled() {
      this.postcode = "";
      this.house_no = "";
      this.city = "";
      this.floor=""
      this.address = "";
      this.street = "";
    },
  },
  mounted() {},
};
</script>
<style>
.pac-container {
  z-index: 10000 !important;
}
</style>
