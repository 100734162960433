<template>
  <div>
    <section class="container py-5">
      <div class="row">
        <div class="col-12">
          <div class="heading">{{ $t("sign_heading") }}</div>
          <div
            v-if="alert"
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
          >
            {{ alertText }}
          </div>
        </div>
        <div class="col-12">
          <b-form v-on:submit.prevent="onSubmit" ref="form_id">
            <div class="row">
              <b-form-group label="Name *" class="col-12 col-md-6">
                <b-form-input
                  v-model="username"
                  required=""
                  type="text"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('form_email') + ' *'"
                class="col-md-6 col-12"
              >
                <b-form-input
                  autocomplete="off"
                  readonly
                  required=""
                  onfocus="this.removeAttribute('readonly');"
                  v-model="email"
                  type="email"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="row">
              <b-form-group
                :label="$t('form_password') + ' *'"
                class="col-12 col-md-6"
              >
                <b-form-input
                  v-model="password"
                  required=""
                  type="password"
                  onfocus="this.removeAttribute('readonly');"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('form_phone') + ' *'"
                class="col-md-6 col-12"
              >
                <b-form-input
                  maxlength="15"
                  required=""
                  v-model="phone_number"
                  type="text"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </div>
            <button type="submit" class="registerBtn">
              <i
                style="color: rgb(123 217 182)"
                class="fa fa-spinner fa-spin"
                v-if="buttonloader"
              ></i
              >{{ $t("send_button") }}
            </button>
          </b-form>
        </div>
      </div>
    </section>
    <section class="sectionDivided py-5">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="content">
              {{ $t("guten_appetit_work") }}, <br />
              <span>{{ $t("for_you_as_well") }}!</span>
            </div>
            <p>{{ $t("over_20") }}</p>
          </div>
        </div>
        <div class="row text-center pt-5">
          <div class="col-12">
            <div class="headingText pb-5">
              <span>{{ $t("why") }}?</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-12 mb-3">
            <div class="card">
              <i class="fa fa-money"></i>
              <h3>{{ $t("increase_your_revenue") }}</h3>
              <p>
                {{ $t("increase_your_description") }}
              </p>
            </div>
          </div>
          <div class="col-md-4 col-12 mb-3">
            <div class="card">
              <i class="fa fa-briefcase"></i>
              <h3>{{ $t("reach_more_customer") }}</h3>
              <p>
                {{ $t("reach_more_customer_description") }}
              </p>
            </div>
          </div>
          <div class="col-md-4 col-12 mb-3">
            <div class="card">
              <i class="fa fa-heart"></i>
              <h3>{{ $t("knowledge_and_support") }}</h3>
              <p>
                {{ $t("knowledge_and_support_description") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="divider"></div>
    <section class="bgWhite pb-5">
      <div class="container">
        <div class="row text-center">
          <div class="col-12 pb-5">
            <div class="headingText">
              {{ $t("our_services") }}<br />
              <span>{{ $t("for_you") }}</span>
            </div>
          </div>
        </div>
        <div class="row pt-5">
          <div class="col-md-4 col-12">
            <h3>
              <strong>{{ $t("increase_your_sales") }}</strong> <br />
              {{ $t("increase_your_sales_small_heading") }}
            </h3>
            <p>
              {{ $t("increase_your_sales_desc_1") }}
              <br /><br />
              {{ $t("increase_your_sales_desc_2") }}
            </p>
          </div>
          <div class="col-12 offset-md-1 col-md-7">
            <div class="services">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="iconBox">
                    <i class="fa fa-user"></i>
                  </div>
                  <p>{{ $t("customer") }}</p>
                </div>
                <div class="col-12 col-md-4">
                  <div class="iconBox">
                    <i class="fa fa-comment"></i>
                  </div>
                  <p>
                    {{ $t("comment") }}
                  </p>
                </div>
                <div class="col-12 col-md-4">
                  <div class="iconBox">
                    <i class="fa fa-desktop"></i>
                  </div>
                  <p>{{ $t("discount") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="dividerAlt"></div>
    <section class="pb-5">
      <div class="container">
        <div class="row text-center">
          <div class="col-12">
            <div class="headingText">
              {{ $t("how_gutenAppetit_work") }} <br />

              <span>{{ $t("easy") }} !</span>
            </div>
          </div>
        </div>
        <div class="row pt-5">
          <div class="col-12 col-md-8 offset-md-2">
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="thumbnailIcon">
                  <span class="thumbNumber">1</span>
                  <i class="fas fa-hamburger"></i>
                </div>
                <div class="text">
                  {{ $t("customer_chooses") }}
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="thumbnailIcon">
                  <span class="thumbNumber">2</span>
                  <i class="fa fa-briefcase"></i>
                </div>
                <div class="text">
                  {{ $t("receive_the_order_directly") }}
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="thumbnailIcon">
                  <span class="thumbNumber">3</span>
                  <i class="fas fa-motorcycle"></i>
                </div>
                <div class="text">
                  {{ $t("prepare_and_deliver") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bannerSection">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="heading">
              {{ $t("more_orders") }}
            </div>
            <div class="row">
              <div class="col-12 col-md-8 offset-md-2">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <div class="step">
                      <div class="stepNumber">1</div>
                      <span class="stepSpan">{{
                        $t("fill_out_the_form")
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="step">
                      <div class="stepNumber">2</div>
                      <span class="stepSpan">{{ $t("upload_you_menu") }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="step">
                      <div class="stepNumber">3</div>
                      <span class="stepSpan">{{ $t("hear_back") }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                {{ $t("information_about_signing") }}
                <br />
                {{ $t("information_about_signing_2") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { credentialRequest } from "../api/services";

export default {
  name: "SignupRestaurant",
  data() {
    return {
      buttonloader: false,
      alert: false,
      alertText: "",
      username: "",
      email: "",
      password: "",
      phone_number: "",
      changeClasss: false,
    };
  },
  methods: {
    clearFiled() {
      this.email = "";
      this.phone_number = "";
      this.password = "";
      this.username = "";
    },
    onSubmit(evt) {
      evt.preventDefault();
      let apiData = JSON.stringify({
        username: this.username,
        email: this.email,
        password: this.password,
        phone_number: this.phone_number,
        platform: "web",
      });
      this.buttonloader = true;
      credentialRequest(apiData).then((responce) => {
        console.log(responce);
        if (responce.code == 200) {
          this.alert = true;
          this.alertText =
            "Request for restaurant registration has been sent successfully";
          this.buttonloader = false;
          this.changeClasss = true;
          this.clearFiled();
        } else {
          this.alertText = responce.message;
          this.alert = true;
          this.buttonloader = false;
          this.changeClasss = false;
        }
      });
    },
  },
};
</script>

<style>
.heading {
  color: #0a3847;
  font-size: 40px;
  font-weight: 400;
  line-height: 42px;
  margin-bottom: 10px;
}

.bgGrey {
  background: #f8f5f2;
}

.content {
  color: #0a3847;
  font-size: 48px;
  font-weight: 400;
  line-height: 48px;
  margin-bottom: 16px;
}

.content span {
  color: #5cb700;
  letter-spacing: 2px;
}

p {
  color: #0a3847;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
}

.headingText {
  color: #0a3847;
  font-size: 72px;
  font-weight: 400;
  line-height: 1;
}

.headingText span {
  color: #5cb700;
  letter-spacing: 1px;
}

.card {
  height: 100%;
  padding: 3.2rem 1.6rem 3.2rem 2.4rem;
  background: #fff;
  border-radius: 0.3rem;
  text-align: center;
  -webkit-box-shadow: 0 0 0.8rem 0 rgb(0 0 0 / 10%);
  box-shadow: 0 0 0.8rem 0 rgb(0 0 0 / 10%);
}

.card i {
  font-size: 48px;
  color: #5cb700;
}

.card h3 {
  margin: 1.6rem 0 2.4rem;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.card p {
  font-size: 14px;
  color: #0a3847;
  font-weight: 400;
}

.sectionDivided {
  background: linear-gradient(175deg, #f8f5f2 50%, #fff 0);
  padding: 4rem 0;
  overflow: hidden;
}

.dividerAlt {
  background: linear-gradient(175deg, #f8f5f2 50%, #fff 0);
  padding: 8rem 0;
}

.divider {
  padding: 8rem 0;
  background: linear-gradient(175deg, #fff 50%, #f8f5f2 0);
}

.bgWhite {
  background: #f8f5f2;
}

.bgWhite h3 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 16px;
}

.bgWhite p {
  font-size: 14px;
  font-weight: 300;
  color: #0a3847;
}

.services {
  min-height: 480px;
  padding: 90px 64px 24px;
  background: url(/images/computer.png) no-repeat top;
  background-size: 100% auto;
  text-align: center;
}

.iconBox {
  width: 110px;
  height: 110px;
  line-height: 110px;
  text-align: center;
  color: #5cb700;
  border-radius: 50%;
  font-size: 40px;
  background: #f8f5f2;
  margin: 0 auto;
}

.services p {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  color: rgb(51, 51, 51);
  margin-top: 15px;
}

.thumbnailIcon {
  position: relative;
  display: block;
  width: 195px;
  height: 195px;
  line-height: 195px;
  margin: 0 auto 32px;
  border-radius: 50%;
  font-size: 75px;
  text-align: center;
  background: #f8f5f2;
  color: #5cb700;
}

.thumbNumber {
  position: absolute;
  left: 16px;
  bottom: 16px;
  display: block;
  width: 55px;
  height: 55px;
  line-height: 47px;
  background: #0a3847;
  border-radius: 50%;
  border: 04px solid #fff;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.text {
  font-size: 14px;
  color: #0a3847;
  font-weight: 300;
  text-align: center;
}

.bannerSection {
  position: relative;
  padding: 160px 0;
  color: #fff;
  text-align: center;
  background-size: cover;
  background: url(/images/banner.jpg) no-repeat top;
}

.bannerSection::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.bannerSection .heading {
  font-size: 72px;
  color: #fff;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: 1px;
  margin-bottom: 64px;
}

.step {
  padding: 0px 40px;
  margin-bottom: 40px;
}

.stepNumber {
  width: 56px;
  height: 56px;
  line-height: 48px;
  margin: 0 auto 08px;
  font-size: 18px;
  font-weight: 700;
  background: #fff;
  color: #0a3847;
  border: 04px solid #fff;
  border-radius: 50%;
  text-align: center;
}

.stepSpan {
  font-size: 14px;
  font-weight: 300;
}
.registerBtn {
  color: #fff;
  background: #5cb700;
  border-radius: 0;
  border: none;
  font-size: 16px;
  font-weight: 400;
  padding: 15px 25px;
}

@media only screen and (max-width: 576px) {
  .content {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }
  .headingText {
    font-size: 32px;
    line-height: 35px;
    padding-top: 30px;
  }
  .card {
    padding: 30px 15px;
  }
  .card p,
  .bgWhite p {
    width: 100%;
  }
  .bgWhite h3 {
    text-align: center;
    font-size: 28px;
    font-weight: 300;
  }
  .bgWhite h3 strong {
    font-weight: bold;
  }
  .divider {
    display: none;
  }
  .services {
    min-height: auto;
    background: #fff;
    border-radius: 10px;
    padding: 30px 15px;
  }
  .iconBox {
    margin: 0 auto;
  }
  .bannerSection .heading {
    font-size: 30px;
    line-height: 40px;
  }
}
</style>
