<template>
  <div>
    <div ref="paypal"></div>
  </div>
</template>

<script>
// import image from "../assets/lamp.png"
export default {
  name: "HelloWorld",
  props: {
    payment_method: {
      type: String,
    },
  },

  data: function () {
    return {
      loaded: false,
      paidFor: false,
      product: {
        price: this.$store.getters.total_amount,
        description: "leg lamp from that one movie",
        img: "./assets/lamp.jpg",
      },
    };
  },
  mounted: function () {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=AVsmIw-2WqTBkNsbw8Ifvak44loCj-3iDxEh5TLzYUKojLLaUCknO0diPJXszGS-Jsw9HQdP2-7G5ARp&currency=EUR&disable-funding=credit,card";
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    setLoaded: function () {
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.product.description,
                  amount: {
                    currency_code: "EUR",
                    value: this.product.price,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            this.paidFor = true;
            this.$emit("setLoaded", order);
          },
          onError: (err) => {
            console.log(err);
            // this.$emit("setLoaded", err);
          },
        })
        .render(this.$refs.paypal);
    },
  },
};
</script>

<style>
.paypal-button-number-1 {
  display: none;
}
</style>
