<template>
  <div>
    <div v-if="listing.data.length > 0" class="title">{{ $t("orders") }}</div>
    <div v-if="listing.data.length == 0" class="title text-center">
      {{ $t("record_not_found") }}
    </div>
    <div
      class="restaurant-listing"
      v-for="(list, index) in listing.data"
      :key="index"
    >
      <div class="logo-wrapper">
        <img :src="list.restaurant.coverImage" alt="" />
        <span>€ {{ totalPrice(list) }}</span>
      </div>

      <div class="details-wrapper">
        <div @click="redirectDetail(list)">
          <div
            class="restaurant-name d-flex justify-content-between align-items-center"
          >
            <a class="col-md-3" href="#">{{ list.restaurant.name }}</a>
            <div :class="list.order_status == 'rejected' ? 'text-danger' : ''">
              {{ capitalizeFirstLetter(list.order_status) }}
            </div>
          </div>

          <div class="bottom-wrapper">
            <span class="col-md-3">{{ $t("delivery") }} {{ $t("type") }}</span>
            <span>{{ list.ordertype }}</span>
          </div>
          <div class="bottom-wrapper">
            <span class="col-md-3">{{ $t("payment") }} {{ $t("type") }}</span>
            <span v-if="list.payment_type == 0">{{ $t("cash") }}</span>
            <span v-if="list.payment_type == 1">{{ $t("online") }}</span>
          </div>
          <div class="bottom-wrapper" v-if="list.voucher">
            <span class="col-md-3">{{ $t("voucher") }}</span>
            <span>{{ list.voucher.voucher.type }}</span>
            <span class="ml-2">({{ list.voucher.unique_code }})</span>
          </div>
          <div class="bottom-wrapper" v-if="list.voucher">
            <span class="col-md-3">{{ $t("voucher") }} {{ $t("amount") }}</span>
            <span>{{ list.voucher.voucher.voucher_amount }}</span>
            <span class="ml-2">{{
              list.voucher.voucher.type == "Order discount" ? "%" : "€"
            }}</span>
          </div>

          <div
            class="bottom-wrapper"
            v-if="list.voucher && list.voucher.voucher.type == 'Order discount'"
          >
            <span class="col-md-3">{{ $t("voucher") }} discount %</span>
            <span>{{ list.voucher_discount.toFixed(2) + " €" }}</span>
          </div>
        </div>
        <div class="align-items-center ml-3">
          <span v-if="list.customerOrdersDetail.length > 0"
            >{{ list.customerOrdersDetail.length }} Item<span
              v-if="list.customerOrdersDetail.length > 1"
              >s</span
            >
          </span>
          <span
            v-if="
              list.discounteditems.length > 0 &&
              list.customerOrdersDetail.length > 0
            "
            style="padding-left: 4px !important"
          >
            +
          </span>
          <span
            class="ml-1"
            style="font-weight: 700"
            v-if="list.discounteditems.length > 0"
          >
            {{ list.discounteditems.length }}
            Deal<span v-if="list.discounteditems.length > 1">s</span>
          </span>
          <div v-if="list.food_rating == '0'" class="col-md-1 ml-3"></div>
          <b-button
            v-if="list.food_rating == '0' && list.order_status == 'completed'"
            @click="showRatingModel(list)"
            class="btn btn-success btn-sm"
            >{{ $t("rating") }}</b-button
          >
        </div>
        <div class="kitchen" v-if="list.comment">
          <div style="font-weight: 700;margin-right">Comment :</div>
          {{ list.comment }}
        </div>
        <div v-if="list.food_rating != '0'">
          <div class="d-flex align-items-center justify-content-between">
            <div class="mainLabel">{{ $t("food_quality_rating") }}</div>
            <star-rating
              :read-only="true"
              v-bind:increment="0.5"
              v-bind:max-rating="5"
              :rating="parseFloat(list.food_rating)"
              inactive-color="#666"
              active-color="#5CB700"
              v-bind:star-size="12"
              class="ml-4"
            >
            </star-rating>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="mainLabel">{{ $t("delivery_quality_rating") }}</div>
            <star-rating
              :read-only="true"
              v-bind:increment="0.5"
              v-bind:max-rating="5"
              :rating="parseFloat(list.delivery_rating)"
              inactive-color="#666"
              active-color="#5CB700"
              v-bind:star-size="12"
              class="ml-4"
            >
            </star-rating>
          </div>
        </div>
      </div>
    </div>
    <template v-if="isShowModel">
      <Rating
        :show="showModal"
        @closeModal="closeModal"
        :order="order"
        @doneResponce="doneResponce"
      />
    </template>
    <template v-if="isShoworderModel">
      <orderDetail
        :orderShow="isShoworderModel"
        @closeModal="closeModal"
        :order="order"
        @doneResponce="doneResponce"
      />
    </template>
    <pagination
      :data="listing.pagination"
      :limit="4"
      align="center"
      @pagination-change-page="orderPagination"
    >
    </pagination>
  </div>
</template>

<script>
import Rating from "../components/Modals/Rating";
import orderDetail from "../components/Modals/orderDetail";
import StarRating from "vue-star-rating";

export default {
  name: "Favourites",
  props: {
    listing: {
      type: Object,
    },
  },
  data() {
    return {
      order: {},
      showModal: false,
      isShoworderModel: false,
      isShowModel: false,
    };
  },
  components: { Rating, StarRating, orderDetail },
  methods: {
    orderPagination(page = 1) {
      const data = {
        per_page: "10",
        page: page,
      };
      this.$emit("orderPagination", data);
    },
    closeModal(value, type) {
      if (type == "order") {
        this.isShoworderModel = value;
      }
      this.isShowModel = value;
    },

    redirectDetail(object) {
      this.order = object;
      this.isShoworderModel = true;
    },

    totalPrice(object) {
      return object.ordertype == "Delivery"
        ? (
            object.totalprice + object.orderDeliveryAddress.delivery_cost
          ).toFixed(2)
        : object.totalprice.toFixed(2);
    },

    doneResponce(object, ratinValue) {
      var obj = JSON.parse(ratinValue);
      let index = this.listing.data.findIndex((x) => x === object);
      if (index !== -1) {
        this.listing.data[index].food_rating = obj.food_quality_rating;
        this.listing.data[index].delivery_rating = obj.delivery_quality_rating;
      }
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    showRatingModel(object) {
      this.isShowModel = true;
      this.showModal = true;
      this.order = object;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.logo-wrapper {
  flex-direction: column;
}

/* .bottom-wrapper span:nth-child(2) {
  padding-left: 0px;
} */

.title {
  font-size: 20px;
  color: #666;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 10px;
}

.desc {
  font-size: 13px;
  font-weight: 400;
  color: #666;
  line-height: 20px;
}

.label {
  font-size: 15px;
  color: #666;
  padding-top: 20px;
  padding-bottom: 5px;
}

.mainLabel {
  font-size: 11px;
  color: #666;
}

textarea {
  width: 100%;
  resize: none;
  border-radius: 5px;
  border-color: #dedede;
  padding: 5px;
}
</style>
