<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      ref="hide_model"
      id="forget-password"
      size="lg"
      centered
      scrollable
      title="Forget Password"
    >
      <template #modal-header title="Forget Password">
        <h5 class="modal-title">{{ $t("forget_password") }}</h5>
        <button @click="hideModal()" type="button" class="close">×</button>
      </template>
      <div
        v-if="alert"
        role="alert"
        aria-live="polite"
        aria-atomic="true"
        :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
      >
        {{ alertText }}
      </div>
      <b-form v-on:submit.prevent="onSubmit" ref="form_id">
        <b-form-group id="input-group-1" :label="$t('form_email')">
          <b-form-input type="email" required="" v-model="email"></b-form-input>
        </b-form-group>
        <button class="google-btn facebook-btn">
          <i
            style="color: rgb(123 217 182)"
            class="fa fa-spinner fa-spin"
            v-if="buttonloader"
          ></i
          >{{ $t("send_link") }}
        </button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { sendPasswordLink } from "../../api/services";

export default {
  data() {
    return {
      buttonloader: false,
      alert: false,
      alertText: "",
      email: "",
      changeClasss: false,
    };
  },

  components: {},

  mounted() {},

  methods: {
    hideModal() {
      this.alert = false;
      this.alertText = "";
      this.$refs["hide_model"].hide();
    },
    onSubmit(evt) {
      evt.preventDefault();
      let apiData = JSON.stringify({
        email: this.email,
      });
      this.buttonloader = true;
      sendPasswordLink(apiData).then((responce) => {
        if (responce.code == 200) {
          this.alert = true;
          this.alertText = responce.message;
          this.buttonloader = false;
          this.changeClasss = true;
          this.email = "";
        } else {
          this.alertText = responce.message;
          this.alert = true;
          this.buttonloader = false;
          this.changeClasss = false;
        }
      });
    },
  },
};
</script>
