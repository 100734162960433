<template>
  <div>
    <div class="title">{{ $t("address_footer") }}</div>
    <button v-b-modal.add-address class="btn btn-primary mt-4">
      {{ $t("add") }} {{ $t("address_footer") }}
    </button>
    <AddAddress />
    <div
      class="restaurant-listing"
      v-for="(list, index) in this.$store.state.loginUserData.addresses
        .slice()
        .reverse()"
      :key="index"
    >
      <div class="logo-wrapper">
        <img :src="'/images/placeholder.png'" alt="" />
      </div>

      <div class="details-wrapper">
        <div class="restaurant-name">
          <a href="#">{{ bindAddress(list) }}</a>
        </div>
        <div class="kitchen">{{ list.postcode }}</div>
        <div class="bottom-wrapper">
          <span style="color: #007bff" @click="spilceData(list)" href="#"
            >{{ $t("remove") }} {{ $t("address_footer") }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateProfile } from "../api/services";

import AddAddress from "../components/Modals/AddAddress";
export default {
  name: "Addresses",
  data() {
    return {
      addresses: this.$store.state.loginUserData.addresses,
    };
  },
  components: {
    AddAddress,
  },
  methods: {
    spilceData(object) {
      this.addresses = this.$store.state.loginUserData.addresses;
      this.addresses.splice(this.addresses.indexOf(object), 1);
      this.updateProfile();
    },

    bindAddress(object) {
      let house_no = object.house_no ? object.house_no + ", " : "";
      let floor = object.floor ? object.floor + ", " : "";
      let street = object.street ? object.street + ", " : "";
      let postcode = object.postcode ? object.postcode + ", " : "";
      let city = object.city ? object.city : "";
      let completeAddress =
        street + house_no + floor + postcode + city ;
      return completeAddress;
    },
    updateProfile() {
      let apiData = JSON.stringify({
        addresses: JSON.stringify(this.addresses),
        username: this.$store.state.loginUserData.username,
        platform: "web",
      });
      updateProfile(apiData).then((responce) => {
        if (responce.code == 200) {
          this.$store.commit("updateLoginUser", responce.data);
          localStorage.setItem("currentUser", JSON.stringify(responce.data));
          localStorage.setItem("token", JSON.stringify(responce.data.token));
        } else {
          console.log("==>", responce);
        }
      });
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  color: #666;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 10px;
}

.desc {
  font-size: 13px;
  font-weight: 400;
  color: #666;
  line-height: 20px;
}
</style>
