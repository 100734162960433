<template>
  <div>
    <div class="title">{{ $t("change_password") }}</div>
    <div
      v-if="alert"
      role="alert"
      aria-live="polite"
      aria-atomic="true"
      :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
    >
      {{ alertText }}
    </div>
    <b-form @submit="onSubmit">
      <div class="row">
        <b-form-group
          :label="$t('current_password') + ' *'"
          class="col-md-6 col-12"
        >
          <b-form-input
            v-model="current_password"
            type="password"
            placeholder=""
            required
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group
          :label="$t('new_password') + ' *'"
          repeat_password
          class="col-md-6 col-12"
        >
          <b-form-input
            v-model="password"
            type="password"
            placeholder=""
            required
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          :label="$t('repeat_password') + ' *'"
          class="col-md-6 col-12"
        >
          <b-form-input
            v-model="confirm_password"
            type="password"
            placeholder=""
            required
          >
          </b-form-input>
        </b-form-group>
        <div class="col-12">
          <button class="btn btn btn-primary">
            <i
              style="color: rgb(123 217 182)"
              class="fa fa-spinner fa-spin"
              v-if="buttonloader"
            ></i
            >{{ $t("change_password") }}
          </button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { changePassword } from "../api/services";

export default {
  name: "UpdatePassword",
  data() {
    return {
      current_password: "",
      buttonloader: false,
      password: "",
      confirm_password: "",
      alert: false,
      changeClasss: false,
      alertText: "",
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      if (this.password != this.confirm_password) {
        this.alert = true;
        this.alertText = "Password does not match";
        return;
      }
      let apiData = JSON.stringify({
        current_password: this.current_password,
        password: this.password,
        confirm_password: this.confirm_password,
      });
      this.buttonloader = true;
      changePassword(apiData).then((responce) => {
        if (responce.code == 200) {
          this.clearField();
          this.restaurantList = responce.data;
          this.changeClasss = true;
          this.buttonloader = false;
          this.alert = true;
          this.alertText = "Your password has successfully updated";
          this.changeClasss = true;
        } else {
          this.alert = true;
          this.alertText = responce.message;
          this.changeClasss = false;
          this.buttonloader = false;
        }
      });
    },
    clearField() {
      this.password = "";
      this.confirm_password = "";
      this.current_password = "";
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  color: #666;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 10px;
}
</style>
