<template>
  <b-form-group :label="$t('stripe_card')" class="col-md-6 col-12">
    <div id="stripe-element-card" ref="card"></div>
  </b-form-group>
</template>
<script type="text/javascript" src="https://js.stripe.com/v2/"></script>

<script>
let stripe = Stripe(
    `pk_test_51JIsjoJDJJJr4FA7cIvpqE0xI607R6eqiHlgHtMKj85WwJ6oP6oOiX1VxTfWXIZd0VBvTv2AFMghssNrM8WG1KiK00HfsuMG1B`
  ),
  elements = stripe.elements(),
  card = elements.create("card");

export default {
  props: {
    stripetoken: {
      type: Boolean,
    },
  },
  mounted: function () {
    card.mount(this.$refs.card);
    card.on("change", (event) => {
      if (event.complete) {
        const self = this;
        stripe.createToken(card).then(function (result) {
          console.log(result);
          self.$emit("stripeTokken", result);
          return;
        });
        // enable payment button
      } else if (event.error) {
        console.log(event.error);
        // show event.error.message to customer
      }
    });
  },

  watch: {
    stripetoken(val) {
      const self = this;
      stripe.createToken(card).then(function (result) {
        self.$emit("stripeTokken", result);
        return;
      });
    },
  },
};
</script>
<style scoped>
.product-wrapper {
  padding: 10px;
}
.StripeElement {
  border: 1px solid #d7d7d7;
  padding: 10px;
}
</style>
