<template>
  <div class="container py-5">
    <div class="heading">{{ $t("recommend_restaurant") }}</div>
    <div
      v-if="alert"
      role="alert"
      aria-live="polite"
      aria-atomic="true"
      :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
    >
      {{ alertText }}
    </div>
    <div class="subHeading">
      {{ $t("would_like_to_recommend") }}
    </div>
    <b-form v-on:submit.prevent="onSubmit" class="pt-3">
      <div class="row">
        <b-form-group :label="$t('form_name') + ' *'" class="col-12">
          <b-form-input required="" v-model="recommended_name"> </b-form-input>
        </b-form-group>
        <b-form-group :label="$t('form_email') + ' *'" class="col-md-6 col-12">
          <b-form-input required="" type="email" v-model="email"></b-form-input>
        </b-form-group>
        <b-form-group
          :label="$t('form_postcode') + ' *'"
          class="col-md-6 col-12"
        >
          <b-form-input
            required=""
            maxlength="10"
            type="number"
            v-model="postcode"
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('form_r_name') + ' *'" class="col-md-6 col-12">
          <b-form-input required="" v-model="restaurant_name"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('form_town') + ' *'" class="col-md-6 col-12">
          <b-form-input required="" v-model="town"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('form_remarks')" class="col-12">
          <b-form-textarea v-model="remarks" rows="5"></b-form-textarea>
        </b-form-group>
        <button type="submit" class="btn btn-primary ml-3">
          <i
            style="color: rgb(123 217 182)"
            class="fa fa-spinner fa-spin"
            v-if="buttonloader"
          ></i
          >{{ $t("send_button") }}
        </button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { recommendedRestaurnt } from "../api/services";

export default {
  name: "RecommendRestaurant",
  data() {
    return {
      buttonloader: false,
      alert: false,
      alertText: "",
      recommended_name: "",
      email: "",
      postcode: "",
      restaurant_name: "",
      town: "",
      remarks: "",
      changeClasss: false,
    };
  },
  methods: {
    clearFiled() {
      this.email = "";
      this.recommended_name = "";
      this.postcode = "";
      this.restaurant_name = "";
      this.town = "";
      this.remarks = "";
    },
    onSubmit(evt) {
      evt.preventDefault();
      let apiData = JSON.stringify({
        recommended_name: this.recommended_name,
        email: this.email,
        postcode: this.postcode,
        restaurant_name: this.restaurant_name,
        town: this.town,
        remarks: this.remarks,
      });
      this.buttonloader = true;
      recommendedRestaurnt(apiData).then((responce) => {
        if (responce.code == 200) {
          this.alert = true;
          this.alertText = responce.message;
          this.buttonloader = false;
          this.changeClasss = true;
          this.clearFiled();
        } else {
          this.alertText = responce.message;
          this.alert = true;
          this.buttonloader = false;
          this.changeClasss = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.heading {
  color: #666666;
  font-size: 28px;
  font-weight: 400;
  line-height: 42px;
  margin-bottom: 10px;
}

.subHeading {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 10px;
}
</style>
