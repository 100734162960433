<template>
  <div>
    <div v-if="listing.data.length > 0" class="title">
      {{ $t("favourites") }} {{ $t("restaurant") }}
    </div>
    <div v-if="listing.data.length == 0" class="title text-center">
      {{ $t("record_not_found") }}
    </div>

    <div
      class="restaurant-listing"
      v-for="(list, index) in listing.data"
      :key="index"
    >
      <div class="logo-wrapper">
        <img :src="list.coverImage" alt="" />
        <star-rating
          class="pt-2"
          :read-only="true"
          v-bind:max-rating="5"
          :rating="list.rating"
          inactive-color="#666"
          active-color="#5CB700"
          v-bind:star-size="13"
          >(561)
        </star-rating>
      </div>

      <div class="details-wrapper">
        <div class="restaurant-name">
          <a href="#">{{ list.name }}</a>
        </div>
        <div class="kitchen">{{ list.type }}</div>
        <div class="bottom-wrapper">{{ list.location }}</div>
      </div>
    </div>
    <pagination
      :data="listing.pagination"
      :limit="4"
      align="center"
      @pagination-change-page="favPagination"
    >
    </pagination>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";

export default {
  name: "Favourites",
  props: {
    listing: {
      type: Object,
    },
  },
  components: {
    StarRating,
  },
  methods: {
    favPagination(page = 1) {
      const data = {
        per_page: "10",
        page: page,
      };
      this.$emit("favPagination", data);
    },
  },
  mounted() {},
};
</script>

<style scoped>
.logo-wrapper {
  flex-direction: column;
}

.title {
  font-size: 20px;
  color: #666;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 10px;
}

.desc {
  font-size: 13px;
  font-weight: 400;
  color: #666;
  line-height: 20px;
}
</style>
