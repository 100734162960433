import store from "../store/index";
export function clearOrders() {
  store.commit("newsetAddToCart", "");
  store.commit("setSubTotal", 0);
  store.commit("setTotalAmount", 0);
  store.dispatch("setMenuItems", "");
  store.dispatch("setOffer", "");
  store.dispatch("setDeliveryAddress", "");
  store.dispatch("setRestaurantId", "");
  store.commit("setVoucher", "");
}

export function emptyRestaurantData() {
  store.commit("setSearchResturants", "");
  store.commit("setDummySearchResturants", "");
  store.commit("setResturantsCategories", "");
}

function isvalidTimeForOrder(object, h1, m1, h2, m2) {
  var h = object.h;
  var m = object.m;
  return (h1 < h || (h1 == h && m1 <= m)) && (h < h2 || (h == h2 && m <= m2));
}

export function validTimeForOrder(restaurantObject, timeObject) {
  let currentDate = new Date();
  const day_name = currentDate.toLocaleDateString("default", {
    weekday: "long",
  });
  let timeSlots = restaurantObject.timeSlots;

  let filtered = timeSlots.find((t) => t.day === day_name);
  console.log("validTimeForOrder", timeSlots);

  if (filtered.status == 1) {
    let openingLunchTime = filtered.lunch_start_time;
    let closingLunchTime = filtered.lunch_end_time;
    // console.log("start time", openingTime);
    // console.log("closing time", closingTime);
    let openingLunchHour = openingLunchTime?.split(":")[0];
    let openingLunchminutes = openingLunchTime?.split(":")[1];
    let closingLunchHour = closingLunchTime?.split(":")[0];
    let closingLunchminutes = closingLunchTime?.split(":")[1];
    let validLunchTimeForOrder = isvalidTimeForOrder(
      timeObject,
      openingLunchHour,
      openingLunchminutes,
      closingLunchHour,
      closingLunchminutes
    );

    let openingDinnerTime = filtered.dinner_start_time;
    let closingDinnerTime = filtered.dinner_end_time;

    let openingDinnerHour = openingDinnerTime?.split(":")[0];
    let openingDinnerminutes = openingDinnerTime?.split(":")[1];
    let closingDinnerHour = closingDinnerTime?.split(":")[0];
    let closingDinnerminutes = closingDinnerTime?.split(":")[1];
    let validDinnerTimeForOrder = isvalidTimeForOrder(
      timeObject,
      openingDinnerHour,
      openingDinnerminutes,
      closingDinnerHour,
      closingDinnerminutes
    );

    if (!validLunchTimeForOrder && !validDinnerTimeForOrder) {
      return false;
    }
    return true;
  } else {
    return false;
  }
}
