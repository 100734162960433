<template>
<div>
     <Navbar />
  <div class="main-content">
    <router-view></router-view>
  </div>
    <Footer />
</div>
</template>

<script>
import Navbar from '../layouts/Navbar'
import Footer from '../layouts/Footer'

export default {
  name: 'Main',
  components: {
    Navbar,
    Footer 
  }
}
</script>