<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      id="rating-model"
      centered
      ref="rating-model"
      title="Ratings"
    >
      <div
        v-if="alert"
        role="alert"
        aria-live="polite"
        aria-atomic="true"
        :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
      >
        {{ alertText }}
      </div>
      <div class="label pt-0">For Quality Rating</div>
      <star-rating
        v-bind:increment="0.5"
        v-bind:max-rating="5"
        :rating="0.5"
        v-model="food_quality_rating"
        inactive-color="#666"
        active-color="#5CB700"
        v-bind:star-size="20"
      >
      </star-rating>
      <div class="label">Delivery Quality Rating</div>
      <star-rating
        v-bind:increment="0.5"
        v-bind:max-rating="5"
        v-model="delivery_quality_rating"
        inactive-color="#666"
        active-color="#5CB700"
        v-bind:star-size="20"
      >
      </star-rating>
      <div class="label">Reviews</div>
      <textarea
        v-model="review"
        rows="3"
        cols="10"
        placeholder="Review"
      ></textarea>
      <div class="d-flex align-items-center justify-content-end pt-4 mb-2">
        <button class="btn btn-success btn-sm" @click="saveData()">
          <i
            style="color: rgb(123 217 182)"
            class="fa fa-spinner fa-spin"
            v-if="buttonloader"
          ></i>
          Save
        </button>
        <button class="btn btn-danger btn-sm ml-2" @click="hideModal()">
          Close
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { rating } from "../../api/services";

import StarRating from "vue-star-rating";
export default {
  name: "Favourites",
  data() {
    return {
      food_quality_rating: 0.5,
      delivery_quality_rating: 0.5,
      review: "",
      buttonloader: false,
      alert: false,
      alertText: "",
      changeClasss: false,
    };
  },
  props: {
    order: {
      type: Object,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    StarRating,
  },
  methods: {
    hideModal() {
      this.$refs["rating-model"].hide();
      this.$emit("closeModal", false, "rating");
    },
    saveData() {
      let data = JSON.stringify({
        food_quality_rating: this.food_quality_rating,
        delivery_quality_rating: this.delivery_quality_rating,
        order_id: this.order.id,
        review: this.review,
      });
      this.buttonloader = true;
      rating(data).then((responce) => {
        if (responce.code == 200) {
          this.alert = true;
          this.alertText = responce.message;
          this.buttonloader = false;
          this.changeClasss = true;
          this.showModel = true;
          this.$emit("doneResponce", this.order, data);
        } else {
          this.alertText = responce.message;
          this.alert = true;
          this.buttonloader = false;
          this.changeClasss = false;
        }
      });
    },
  },
  mounted() {
    this.$refs["rating-model"].show();
    this.showModel = true;
  },
};
</script>

<style scoped>
.label {
  font-size: 15px;
  color: #666;
  padding-top: 20px;
  padding-bottom: 5px;
}

textarea {
  width: 100%;
  resize: none;
  border-radius: 5px;
  border-color: #dedede;
  padding: 5px;
}
</style>
