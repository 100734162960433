<template>
  <div>
    <div class="title">{{ $t("personal_data") }}</div>
    <div
      v-if="alert"
      role="alert"
      aria-live="polite"
      aria-atomic="true"
      :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
    >
      {{ alertText }}
    </div>
    <b-form @submit="onSubmit">
      <div class="row">
        <b-form-group :label="$t('form_name') + ' *'" class="col-md-6 col-12">
          <b-form-input type="text" v-model="username" placeholder="" required>
          </b-form-input>
        </b-form-group>
        <b-form-group :label="$t('form_email') + ' *'" class="col-md-6 col-6">
          <b-form-input
            disabled=""
            class="disable-input"
            :value="this.$store.state.loginUserData.email"
            type="text"
            placeholder=""
            required
          >
          </b-form-input>
        </b-form-group>

        <b-form-group :label="$t('referral_code')" class="col-md-6 col-6">
          <b-form-input
            disabled=""
            class="disable-input"
            :value="this.$store.state.loginUserData.referral_code"
            type="text"
            placeholder=""
            required
          >
          </b-form-input>
        </b-form-group>
        <div class="pt-3 col-12">
          <button class="btn btn-primary">
            <i
              style="color: rgb(123 217 182)"
              class="fa fa-spinner fa-spin"
              v-if="buttonloader"
            ></i
            >{{ $t("save_data") }}
          </button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { updateProfile } from "../api/services";
export default {
  data() {
    return {
      username: this.$store.state.loginUserData.username,
      buttonloader: false,
      alert: false,
      alertText: "",
      changeClasss: false,
    };
  },
  name: "PersonalData",
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let addresses = this.$store.state.loginUserData.addresses;
      let apiData = JSON.stringify({
        addresses: JSON.stringify(addresses),
        username: this.username,
        platform: "web",
      });
      this.buttonloader = true;
      updateProfile(apiData).then((responce) => {
        console.log(responce);
        if (responce.code == 200) {
          this.$store.commit("updateLoginUser", responce.data);
          localStorage.setItem("currentUser", JSON.stringify(responce.data));
          localStorage.setItem("token", JSON.stringify(responce.data.token));
          this.alert = true;
          this.alertText = responce.message;
          this.buttonloader = false;
          this.changeClasss = true;
        } else {
          this.buttonloader = false;
          this.alert = true;
          this.alertText = responce.message;
          this.changeClasss = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  color: #666;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 10px;
}

.textWrap {
  background-color: #ebebeb;
  border-radius: 2px;
  background-clip: padding-box;
  padding: 3px 7px;
  font-size: 13px;
  font-weight: 400;
  color: #666;
  line-height: 20px;
}

.border-bottom {
  border: 3px solid #ebebeb;
}

.desc {
  font-size: 13px;
  font-weight: 400;
  color: #666;
  margin-top: -5px;
}
</style>
